import React, { Component } from 'react';
import Slot from './slot';

const dayMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
};

const get_part_loads = slots => {
    let out = [];
    let jobs = Object.entries(slots);
    for (let i=0; i < jobs.length; i++) {
        out = out.concat(jobs[i][1]);
    }
    out = out.filter(x => x.pallets < 13 && x.pre !== true);
    return out
}

export default class SlotHour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slots: []
        };
    }

    mapSlotsToState = (slots, b_slots) => {
        let this_slots = [];
        if (this.props.day in slots) {
            try {
                if (this.props.hour === "SMALL") {
                    this_slots = get_part_loads(slots[this.props.day]);

                } else {
                    if (this.props.hour in slots[this.props.day]) {
                        this_slots = slots[this.props.day][this.props.hour] || [];
                        this_slots = this_slots.filter(x => x.pallets >= 13 || x.pre === true);
                    }
                }
    
            } catch(e) {
                console.log("ERROR", e, this.props.hour);
                this_slots = [];
            }
        }
        
        for (let i=0; i < b_slots.length; i++) {
            if (b_slots[i].block_days.indexOf(dayMap[this.props.weekday]) !== -1) {
                if (this.props.hour >= b_slots[i].block_from && this.props.hour < b_slots[i].block_to) {
                    this_slots.push(JSON.parse(JSON.stringify(b_slots[i])));
                }
            }
        }

        this.setState({slots: this_slots})
    }

    componentDidMount() {
        this.mapSlotsToState(this.props.slots, this.props.block_slots);
    }

    UNSAFE_componentWillReceiveProps(newprops) {
        this.mapSlotsToState(newprops.slots, this.props.block_slots);
    }

    render() {
        return (
            <div className="slot-hour">
                {this.state.slots.map((s,i) => <Slot selected={this.props.selected.id === s.id} tick={this.props.tick} view={() => this.props.view(s)} slot={s} key={i} />)}
            </div>
        );
    }
}
