import { api_url } from '../../config';
import Cookies from 'universal-cookie';

import {
    LOGIN,
    LOGIN_START,
    LOGIN_FAIL,
    LOGOUT,
    SET_PASS,
    RESET_PASS,
    PAUSE_BACKGROUND,
    SET_WALLPAPER,
    TOGGLE_WALLPAPER_MODE,
    VIEW_BACKGROUND,
    UN_VIEW_BACKGROUND
} from '../action_list';
import { api } from '../../utils/api';

export const view_background = () => dispatch => dispatch({type:VIEW_BACKGROUND});
export const un_view_background = () => dispatch => dispatch({type:UN_VIEW_BACKGROUND});

export function reset_password(email) {
    return (dispatch) => {
        api({action:'reset_password', email_address:email},
        (data) => {
            if (data.success === true) {
                dispatch({type: RESET_PASS});
            }
        });
    }
}


export function set_pass() {
    return (dispatch) => {
        dispatch({type:SET_PASS})
    }
}

export function login(user, password, cb, wallpaper=null) {
    return (dispatch) => {
        dispatch({type: LOGIN_START});
        fetch(api_url,{
            method: 'POST',
            mode:'cors',
            headers: {'Accept': 'application/json','Content-Type': 'application/json'},
            body:JSON.stringify({action:'login', email: user, password:password, wallpaper:wallpaper})
        }).then(response => response.json()).then(
            (data) => {
                if (data.success === true) {
                    cb(data.token);
                    dispatch({type:LOGIN, unavailable_dates: data.unavailable_dates, background_style: data.background_style, token: data.token, name: data.name, must_set_pass:data.must_set_pass, user_id: data.user_id, account_type: data.account_type});
                } else {
                    dispatch({type:LOGIN_FAIL});
                }
            }
        )
    }
}

export const set_wallpaper = (wallpaper) => dispatch => {
    dispatch({type:SET_WALLPAPER, wallpaper: wallpaper});
}

export function logout() {
    const cookies = new Cookies();
    cookies.remove('token');
    return (dispatch) => dispatch({type:LOGOUT});
}

export function token_login(token) {
    return (dispatch) => {
        dispatch({type: LOGIN_START});
        api({action:"token_login", token:token},
        (data) => {
            if (data.success === true) {
                dispatch({
                    type:LOGIN,
                    unavailable_dates: data.unavailable_dates,
                    background_style: data.background_style,
                    token: data.token,
                    must_set_pass: data.must_set_pass,
                    name: data.name,
                    user_id: data.user_id,
                    account_type: data.account_type
                });
            }
        });
    }
}

export const pause_background = () => dispatch => {
    dispatch({type:PAUSE_BACKGROUND})
}

export const toggle_wallpaper_mode = (wallpaper, token=null) => dispatch => {
    const cookies = new Cookies();
    let cookies_ok = cookies.get('cookies_ok') || false;
    if (cookies_ok === 'ok') {
        cookies.set('wallpaper', wallpaper, {
            path:'/',
            maxAge: 60*60*24*7*50,
    });
    }

    dispatch({type:TOGGLE_WALLPAPER_MODE, mode: wallpaper});
    if (token !== null) {
        api({action: 'change_background', style: wallpaper, token:token},
        (data) => {});
    }
}