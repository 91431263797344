import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import moment from 'moment';

export class Notification extends Component {
    render() {
        const { notification } = this.props;
        return (
            <div onClick={notification.slot_id !== null ? () => this.props.view() : () => {}} className="notification new" style={notification.slot_id !== null ? {cursor:'pointer'} : {}}>
                <div className="top">
                    <span className="title">{notification.title}</span>
                    <div className="dot" style={{backgroundColor:notification.color}}></div>
                </div>
                <div className="text">
                    {notification.content}
                </div>
                <div className="bottom">
                    {moment(notification.time).format("HH:mm DD/MM")}
                    <div>
                        {notification.slot_id !== null ? <span className="action" onClick={() => this.props.view()}>View</span> : null}
                    </div>
                </div>
            </div>
        );
    }
}

class NotificationStack extends Component {
    UNSAFE_componentWillReceiveProps(newprops) {
        if (newprops.ticker > this.props.ticker) {
            this.forceUpdate();
        }
    }
    

    render() {
        return (
            <div className="notification-stack">
                {this.props.notifications.map((n,i) => <Notification view={() => this.props.view(n.slot_id)} key={i} notification={n} />)}
            </div>
        );
    }
}


function mapStateToProps(state) {
	return {
        notifications: state.notification_reducer.notifications,
        ticker: state.notification_reducer.ticker,
        slots: state.slots_reducer.slots
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationStack);
