import { api } from '../../utils/api';

import {
    GET_SLOTS,
    CREATE_SLOT,
    EDIT_SLOT,
    RESET_REFRESH,
    UPDATE_SLOT_STATUS,
    VIEW_SLOT,
    CREATE_BLOCK_SLOT,
    VIEW_BLOCK_SLOT,
    DELETE_SLOT,
    UPDATE_SLOTS,
    SEARCH,
    SEARCH_OTHER,
    SLOT_AVAILABLE
} from '../action_list';

export const search_other = (term, cb) => dispatch => {
    dispatch({type: SEARCH_OTHER, term:term});
    cb();
}

export const toggle_slot_available = (token, date_hour) => {
    return (dispatch) => {
        dispatch({type: SLOT_AVAILABLE, date_hour: date_hour});
        api({action: 'toggle_slot_available', token:token, date_hour: date_hour}, () => {});
    }
}


export const search = (term, cb) => dispatch => {
    dispatch({type:SEARCH, term:term});
    cb();
}

export const get_slots = (token, callback) => {
    return (dispatch) => {
        api({action: 'get_slots', token: token},
        (data) => {
            dispatch({type: GET_SLOTS, slots: data.slots, other_jobs: data.other_jobs, max_mod: data.max_mod});
            if (callback) {
                callback();
            }
        });
    }
}

export const update_slots = (token, max_mod) => {
    return (dispatch) => {
        api({action:'update_slots', token:token, max_mod: max_mod},
        (data) => {
            dispatch({type:UPDATE_SLOTS, slots: data.slots, max_mod: data.max_mod});
        })
    }
}

export const create_slot = (token, slot, cb) => {
    return (dispatch) => {
        api({action: 'create_slot', token:token, slot:slot},
        (data) => {
            if (data.success === true) {
                if (data.new === true) {
                    dispatch({type:CREATE_SLOT, slot:data.slot});
                } else {
                    dispatch({type: EDIT_SLOT, slot:data.slot});
                }
                cb();
            }
        })
    }
}

export const edit_slot = (token, slot, cb) => {
    return (dispatch) => {
        api({action: 'edit_slot', token: token, slot:slot},
        (data) => {
            if (data.success === true) {
                dispatch({type: EDIT_SLOT, slot: data.slot});
                cb();
            }
        });
    }
}

export const view_block_slot = (slot, cb) => {
    return (dispatch) => {
        dispatch({type: VIEW_BLOCK_SLOT, slot: slot});
        cb();
    }
}



export const reset_refresh = () => {
    return (dispatch) => {
        dispatch({type: RESET_REFRESH});
    }
}


export const create_pre = (token, slot) => {
    return (dispatch) => {
        api({token:token, action:'create_pre', slot:slot},
        (data) => {
            if (data.success === true) {
                dispatch({type: CREATE_SLOT, slot:data.slot});
            }
        });
    }
}


export const update_slot_status = (token, id, type, issues=null, comment=null) => {
    return (dispatch) => {
        api({action: 'update_slot_status', token: token, id: id, type: type, issues:issues, comment: comment},
        (data) => {
            if (data.success === true) {
                dispatch({type: UPDATE_SLOT_STATUS, slot: data.slot});
            }
        });
    }
}


export const view_slot = (slot, cb=null) => {
    return (dispatch) => {
        dispatch({type: VIEW_SLOT, slot:slot});
        if (cb !== null) {
            cb();
        }
    }
}

export const change_status_time = (token, slot_id, times, cb=null) => {
    return (dispatch) => {
        api({action:'change_status_time', token:token, slot_id: slot_id, times:times},
        (data) => {
            dispatch({type: UPDATE_SLOT_STATUS, slot: data.slot});
            if (cb !== null) {
                cb();
            }
        })
    }
}


export const create_block_booking = (token, slot, nw, cb=null) => {
    return (dispatch) => {
        api({action: nw === true ? 'create_block_booking' : 'update_block_booking', slot:slot, token: token},
        (data) => {
            if (data.success === true) {
                dispatch({type: CREATE_BLOCK_SLOT, slot: data.slot, nw:nw});
                if (cb !== null) {
                    cb();
                }
            }
        });
    }
}


export const delete_slot = (token, id, block, cb=null) => {
    return (dispatch) => {
        api({token: token, action:'delete_slot', slot_id: id},
        (data) => {
            if (data.success === true) {
                dispatch({type: DELETE_SLOT, block:block, slot: data.slot});
                if (cb !== null) {
                    cb();
                }
            }
        });
    }
}
