import React from 'react';
import './App.css';
import MainApp from './main_app';
import { Provider } from 'react-redux';
import store from './store';
import LoadingScreen from './loading';
import LoginScreen from './login';
import DataLayer from './layers/data';
import PauseButton from './components/visual/pause_background';
import Background from './components/visual/background';
import NotifySound from './components/sound';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Provider store={store}>
                    <Background>
                        <PauseButton />
                        <NotifySound />
                            <LoginScreen>
                                <LoadingScreen>
                                    <DataLayer>
                                        <MainApp />
                                    </DataLayer>
                                </LoadingScreen>
                            </LoginScreen>
                    </Background>
                </Provider>
        );
    }
}

export default App;
