import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

export default class ImageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          height:1,
          width:1,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Modal
            isOpen={this.props.visible}
            onRequestClose={this.props.close}
            style={customStyles}
            >
                <div className="image-modal" style={{width:this.props.width, height: this.props.height}}>
                    <img alt="Load Report" src={this.props.image} className="modal-image" style={{width:this.props.width, height: this.props.height}} />        
                </div>
            </Modal>
        );
  }
}
