import React, { Component } from 'react';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import Close from '@material-ui/icons/Close';
import ClickImage from '../visual/click_image';
import ImageModal from '../modals/image_modal';
import { TextField, IconButton } from '@material-ui/core';
import Search from '@material-ui/icons/Search';


class CommentsTable extends Component {
    render() {
        return (

                <div style={{flex:1, maxHeight:120, overflowY:'auto'}}>
                    <table className="comments-table">
                        <thead>
                            <tr>
                                <th style={{width:'auto !important'}}>User</th>
                                <th style={{width:'auto !important'}}>Comment</th>
                                <th style={{width:'auto !important'}}>Time</th>
                            </tr>

                        </thead>
                        <tbody>
                            {this.props.comments.reverse().map((c,i) => <tr key={i}>
                                <td style={{width:'auto !important'}}>{c.user}</td>
                                <td style={{width:'auto !important'}}>{c.comment}</td>
                                <td style={{width:'auto !important'}}>{moment(c.time).format("HH:mm DD/MM/YY")}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            
        );
      
    }
}

class OtherJob extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const {job} = this.props;
        return (
            <div className="other-job">
                <span className="other-job-title">{job.ref}</span>
                <div style={{display: 'flex', flexDirection:'row'}}>
                    <div style={{display:'flex', flexDirection:'row', padding:10, flex:1, minWidth:'50%', overflowX:'auto'}}>
                        {job.pictures.map((p,i) => <ClickImage show={(base) => this.props.showimage(base, p.width, p.height)} key={i} img={p} />)}
                    </div>
                    <div style={{flex:1}}>
                        <CommentsTable comments={job.comments} />
                    </div>
                </div>
            </div>
        );
    }
}

class OtherJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
           image: null,
           image_width: null,
           image_height: null,
        };
    }

    showimage = (base, width, height) => {
        let ratio;

        if (width > height) {
            if (width > 750) {
                ratio = width / height;
                width = 750;
                height = 750 / ratio;
            }
        } else {
            ratio = height / width;
            height = 750;
            width = 750 / ratio;
        }

        this.setState({image: base, image_width: width, image_height: height});
    }

    handle_search = text => {
        text = text.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join('');
        this.setState({search_term: text});
    }

    search = () => {
        if (this.state.search_term.length > 2) {
            this.props.search_other(this.state.search_term, () => this.setState({search_term: null}));
        }
    }


    render() {
        let jobs;
        if (this.props.term !== null) {
            jobs = this.props.search_results_other;
        } else {
            jobs = this.props.other_jobs;
        }

        return (
                <div className="new-booking-form other-job-form">
                    <ImageModal 
                        image={this.state.image}
                        width={this.state.image_width}
                        height={this.state.image_height}
                        close={() => this.setState({image:null})}
                        visible={this.state.image !== null} />

                    <div style={{flexDirection:'row', alignItems:'center'}}>
                        <Close onClick={this.props.cancel} style={{cursor:'pointer', fontSize:29, position:'relative', top:7, marginRight:10, color:'#000'}} />
                        <span className="heading">Other Jobs</span>
                    </div>

                    <div style={{alignSelf:'stretch', padding:10, display:'flex', flexDirection:'row'}}>
                        <TextField value={this.state.search_term} onChange={e => this.handle_search(e.target.value)} />
                        <IconButton onClick={this.props.term !== null ? () => this.props.search_other(null, () => {}) : () => this.search()}>
                            {this.props.term !== null ? <Close /> : <Search />}
                        </IconButton>
                    </div>

                    <div style={{display: 'flex', alignSelf:'stretch', flexDirection:'column', justifyContent:'flex-start', overflowY:'auto'}}>
                        {jobs.map((j,i) => <OtherJob showimage={this.showimage} job={j} key={i} />)}
                    </div>

                </div>
        );    
    }
}

function mapStateToProps(state) {
	return {
        other_jobs: state.slots_reducer.other_jobs,
        search_results_other: state.slots_reducer.search_results_other,
        term: state.slots_reducer.search_term_other
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherJobs);