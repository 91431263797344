import React from 'react';

import './App.css';

import Calendar from './components/functional/calendar';
import LeftMenu from './components/functional/left_menu';
import Welcome from './components/functional/welcome';
import NotificationStack, { Notification } from './components/functional/notifications';
import Today from './components/functional/today';
import NextUp from './components/functional/next_up';
import SearchBox from './components/functional/search_box';

import NewBookingForm from './components/forms/new_booking_form';
import NewHaulierForm from './components/forms/new_haulier_form';
import EditBookingForm from './components/forms/edit_booking_form';
import BlockBookingForm from './components/forms/block_blooking_form';
import SearchForm from './components/forms/search_form';
import OtherJobs from './components/forms/other_images';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './redux/actions';

import MustSetPass from './components/modals/must_set_pass';

import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

import ReactTimeout from 'react-timeout';


class MainApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            middle_left_class:'main-middle-left',
            middle_right_class:'main-middle-right',
            middle_left_showing: false,
            showing:null,
            full: window.innerWidth > 1400,
            menu_showing: true,
            hide_menu_elems: false
        }
    }

    resize = event => {
        if (window.innerWidth > 1400) {
            this.setState({full:true});
        } else {
            this.setState({full: false});
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.shouldHide, false)
    }

    closeForm = () => {
        this.hideMiddleLeft(() => this.setState({
        showing:null,
        }, () => this.props.view_slot(null)));
    }

    showMiddleLeft = (showing) => {
        this.setState({
            middle_left_class: 'main-middle-left middle-left-anim',
            middle_right_class: 'main-middle-right middle-right-anim',
            middle_left_showing: true,
            showing: showing
        });
    }

    hideMiddleLeft = (cb=null) => {
        this.setState({
            middle_left_class: 'main-middle-left middle-left-anim-back',
            middle_right_class: 'main-middle-right middle-right-anim-back',
            middle_left_showing: false
        }, () => {
        if (cb!==null) { cb(); }
        });
    }

    toggleMiddleLeft = (showing) => {
        if (this.state.showing === 'view_slot') {

        } else {

        if (this.state.middle_left_showing === true && this.state.showing === showing) {
            this.hideMiddleLeft();
        } else {
            if (this.state.middle_left_showing === false) {
                this.showMiddleLeft(showing);
            } else {
                this.hideMiddleLeft(() => this.showMiddleLeft(showing));
            }
        }
        }
    }

    hide_menu = () => {
        this.setState({menu_showing: false}, () => this.props.setTimeout(() => this.setState({hide_menu_elems: true}), 350));
    }

    show_menu = () => {
        this.setState({menu_showing: true, hide_menu_elems: false});
    }

    render() {
        let left;
        if (this.state.showing === 'new_booking') {
            left = (<NewBookingForm cancel={this.closeForm}  />);

        } else if (this.state.showing === 'new_haulier') {
            left = (<NewHaulierForm cancel={this.closeForm} />);

        } else if (this.state.showing === 'view_slot') {
            left = (<EditBookingForm cancel={this.closeForm} />)

        } else if (this.state.showing === 'new_block_booking') {
            left = (<BlockBookingForm cancel={this.closeForm} />);

        } else if (this.state.showing === 'search') {
            left = (<SearchForm 
                    results={this.props.search_results}
                    cancel={this.closeForm}
                    view={slot => {
                        if (slot.block === true) {
                            this.props.view_block_slot(slot, () => this.toggleMiddleLeft('new_block_booking'));
                        } else {
                            this.props.view_slot(slot, () => this.toggleMiddleLeft('view_slot'));
                        }
                        }} />)
                        
        } else if (this.state.showing === 'other_jobs') {
            left = (<OtherJobs cancel={this.closeForm} />);
        }

        return (
            <div className="App">

                <MustSetPass token={this.props.token} visible={this.props.must_set_pass} close={()=>this.setState({must_set_pass:false}, () => this.props.set_pass())} />


                <div className={`top-bar ${this.props.viewing_background === true ? 'hidden' : 'menu_is_showing'}`}>
                    {this.state.full ? <Welcome className="welcome-message">{this.props.name}</Welcome> : null}
                    
                    <Today slots={this.props.slots} />
                    
                    {this.state.full === false && this.props.notifications.length > 0 ?  <Notification style={{margin:"20px !important"}} view={() => this.props.view_slot(this.props.slots.filter(x => x.id === this.props.notifications[0].slot_id)[0], () => this.toggleMiddleLeft('view_slot'))} notification={this.props.notifications[0]} /> : <div></div>}
                </div>

                <div className={`main-section ${this.props.viewing_background === true ? 'hidden' : ''} `}>
                    {this.state.full ? <div className="main-left">
                        <div style={{flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
                                
                            <SearchBox vanish={!this.state.menu_showing} view={() => this.toggleMiddleLeft('search')} />
                            
                            <NextUp
                            vanish={!this.state.menu_showing} 
                                refresh={this.props.refresh}
                                slots={this.props.slots}
                                view={slot => {
                                if (slot.block === true) {
                                    this.props.view_block_slot(slot, () => this.toggleMiddleLeft('new_block_booking'));
                                } else {
                                    this.props.view_slot(slot, () => this.toggleMiddleLeft('view_slot'));
                                }
                                }} />

                            <LeftMenu vanish={!this.state.menu_showing} showMiddleLeft={this.toggleMiddleLeft} />
                        </div>

                        <div style={{alignSelf:'stretch', display:'flex', justifyContent:'center', padding:5}}>
                            <div onClick={this.state.menu_showing === true ? this.hide_menu : this.show_menu} style={{backgroundColor:'rgba(0,0,0,0.5)', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:100, cursor:'pointer'}}>
                                {this.state.menu_showing === true ? <LeftIcon style={{fontSize:50, color:'#fff'}} /> : <RightIcon style={{fontSize:50, color:'#fff'}} />}
                            </div>
                        </div>
                    </div> : null}

                    <div className="main-middle">
                        <div className={this.state.middle_left_class}>
                            {left}
                        </div>
                        
                        <div className={this.state.middle_right_class}>
                            <Calendar menu_is_hidden={!this.state.menu_showing} full={this.state.full} selected={this.props.slot_in_view || {id:null}} view={slot => {
                                    if (slot.block === true) {
                                        this.props.view_block_slot(slot, () => this.toggleMiddleLeft('new_block_booking'));
                                    } else {
                                        this.props.view_slot(slot, () => this.toggleMiddleLeft('view_slot'));
                                    }
                                    }} />
                        </div>
                    </div>

                    {this.state.full ? 
                        <div className="main-right">
                            <NotificationStack view={(id) => this.props.view_slot(this.props.slots.filter(x => x.id === id)[0], () => this.toggleMiddleLeft('view_slot'))} />
                        </div>
                        : null}
                </div>
                <div className="bottom-bar"></div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        notifications: state.notification_reducer.notifications,
        slot_in_view: state.slots_reducer.slot_in_view,
        slots: state.slots_reducer.slots,
        name: state.user_reducer.name,
        must_set_pass: state.user_reducer.must_set_pass,
        token: state.user_reducer.token,
        search_results: state.slots_reducer.search_results,
        search_term: state.slots_reducer.search_term,
        viewing_background: state.user_reducer.viewing_background,
        user_id: state.user_reducer.id
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default ReactTimeout(connect(mapStateToProps, mapDispatchToProps)(MainApp));