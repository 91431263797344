import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import moment from 'moment';
import ReactTimeout from 'react-timeout';


class Background extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        }
    }

    componentDidMount() {
        this.update_wallpaper();
        this.update_timeout();
    }

    update_timeout = () => {
        this.update_cycle();
        this.props.setTimeout(this.update_timeout, 30000);
    }

    update_cycle = () => {
        if (moment().minute() === 0 && this.state.checked === false) {
            this.update_wallpaper();
            this.setState({checked: true});
        } else if (moment().minute() === 1) {
                this.setState({checked: false});
        }
    }

    update_wallpaper = () => {
        fetch("https://api.logistics.albanyproducts.co.uk/wallpaper", {method:'POST', headers:{'Accept': 'application/json', 'Content-Type': 'application/json'}}).then(resp => resp.json()).then(data => this.props.set_wallpaper(data.wallpaper));
    }

    render() {
        return (
        <div style={{color:'#fff', backgroundSize:'cover', backgroundRepear:'no-repeat', width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundImage: `url('${this.props.wallpaper}')`, backgroundPosition:'cover'}}>
                {this.props.children}
        </div>);
    }
}

function mapStateToProps(state, props) {
	return {
        paused: state.user_reducer.background_paused,
        wallpaper: state.user_reducer.wallpaper,
        wallpaper_mode: state.user_reducer.wallpaper_mode
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default ReactTimeout(connect(mapStateToProps, mapDispatchToProps)(Background));