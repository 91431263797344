import {
    GET_HAULIERS,
    CREATE_HAULIER,
    LOGOUT
} from '../action_list';


let haulier_state = {
    hauliers:[]
};

let hauliers;


const haulier_reducer = (state=haulier_state, action) => {
    switch(action.type) {
        case LOGOUT:
            return haulier_state;
            
        case GET_HAULIERS:
            state = Object.assign({}, state, {hauliers: action.hauliers});
            return state;

        case CREATE_HAULIER:
            hauliers = state.hauliers;
            hauliers.push(action.haulier);
            state = Object.assign({}, state, {hauliers: hauliers});
            return state;
        
        default:
            return state;
    }
}

export default haulier_reducer;