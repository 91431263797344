import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import { api_url } from '../../config';

export default class ReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report_type:'bookings',
            from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            to: moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD'),
        }
    }

    handle_from = d => {
        this.setState({from:d});
    }

    handle_to = d => {
        this.setState({to:d});
    }

    download_report = () => {
        fetch(api_url, {
            method:'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body:JSON.stringify({action: 'get_report', report:this.state.report_type, start: this.state.from, end: this.state.to, token:this.props.token})
        }).then(resp => resp.json()).then(
            data => {
                if (data.success === true) {
                    window.open(data.report_url);
                }
            }
        )
    }

    render() {
        return(
            <Dialog open={this.props.visible} onClose={() => this.props.close()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Reports</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select report options
                    </DialogContentText>

                    <div style={{padding:10, display: 'flex', flexDirection:'column'}}>
                    
                    <Select
                            autoWidth={true}
                            onChange={e => this.setState({report_type: e.target.value})}
                            value={this.state.report_type}
                        >
                            <MenuItem value="bookings">List Bookings Report</MenuItem>
                            <MenuItem value="haulier">Haulier Performance</MenuItem>
                            <MenuItem value="timings">Timing Matrix</MenuItem>
                    </Select>

                    <div style={{height:20}}></div>
                    <TextField
                        id="date-from"
                        label="Start"
                        type="date"
                        defaultValue={this.state.from}
                        InputLabelProps={{shrink:true}}
                        onChange={e => this.handle_from(e.target.value)}
                        />
                    <div style={{height:20}}></div>
                    <TextField
                        id="date-to"
                        label="End"
                        type="date"
                        defaultValue={this.state.to}
                        InputLabelProps={{shrink:true}}
                        onChange={e => this.handle_to(e.target.value)}
                        />
                      
                    </div>
                </DialogContent>

                <DialogActions>
                    <div style={{alignSelf:'stretch', flex:1, flexDirection:'row', display:'flex', justifyContent:'space-between'}}>
                        <Button onClick={this.props.close} color="primary">
                            Cancel
                        </Button>
                        
                        <Button onClick={() => this.download_report()} color="primary">
                            Get Report
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

}
