import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];

const pad = (x) => {
    x = x.toString()
    while (x.length < 2) {
        x = '0' + x;
    }
    return x;
}

export default class StatusTimes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    minutes = () => {
        let out = [];
        for (var i=0; i < 60; i++) {
            out.push(i);
        }
        return out;
    }

    update = (typ, t) => {
        if (typ === 'arrive_hour') {
            this.props.update('arrive', this.props.arrive.hour(t).format());
        } else if (typ === 'arrive_min') {
            this.props.update('arrive', this.props.arrive.minute(t).format());

        } else if (typ === 'start_hour') {
            this.props.update('start', this.props.start.hour(t).format())
        } else if (typ === 'start_min') {
            this.props.update('start', this.props.start.minute(t).format());

        } else if (typ === 'end_hour') {
            this.props.update('end', this.props.end.hour(t).format())
        } else if (typ === 'end_min') {
            this.props.update('end', this.props.end.minute(t).format());

        } else if (typ === 'depart_hour') {
            this.props.update('depart', this.props.depart.hour(t).format())
        } else if (typ === 'depart_min') {
            this.props.update('depart', this.props.depart.minute(t).format());
        }
    }

    render() {
        const minutes = this.minutes();
        return(
            <Dialog open={this.props.visible} onClose={() => this.props.close()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change Status Times</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You can amend status times below
                    </DialogContentText>

                    {this.props.arrive !== null ?
                    <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', padding:10, marginBottom:15}}>
                        <span className="hour-label">Arrived</span>
                        <div style={{ display: 'flex', flexDirection:'row'}}>

                            <Select
                                style={{width:90, marginRight:15}}
                                onChange={e => this.update('arrive_hour', e.target.value)}
                                value={this.props.arrive.hour()}
                            >
                                {hours.map((h,i) => <MenuItem value={h} key={i}>{pad(h)}</MenuItem>)}
                            </Select>

                            <Select
                                style={{width:90}}
                                onChange={e => this.update('arrive_min', e.target.value)}
                                value={this.props.arrive.minute()}
                            >
                                {minutes.map((m,i) => <MenuItem value={m} key={i}>{pad(m)}</MenuItem>)}
                            </Select>

                            </div>
                    </div>: null}

                    {this.props.start !== null ?    
                    <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', padding:10, marginBottom:15}}>
                        <span className="hour-label">Load/Tip Started</span>
                        <div style={{display: 'flex', flexDirection:'row'}}>

                            <Select
                                style={{width:90, marginRight:15}}
                                onChange={e => this.update('start_hour', e.target.value)}
                                value={this.props.start.hour()}
                            >
                                {hours.map((h,i) => <MenuItem value={h} key={i}>{pad(h)}</MenuItem>)}
                            </Select>

                            <Select
                                style={{width:90}}
                                onChange={e => this.update('start_min', e.target.value)}
                                value={this.props.start.minute()}
                            >
                                {minutes.map((m,i) => <MenuItem value={m} key={i}>{pad(m)}</MenuItem>)}
                            </Select>
                            
                        </div>
                    </div> : null}

                    {this.props.end !== null ?
                    <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', padding:10, marginBottom:15}}>
                        <span className="hour-label">Load/Tip Finished</span>
                        <div style={{display: 'flex', flexDirection:'row'}}>

                            <Select
                                style={{width:90, marginRight:15}}
                                onChange={e => this.update('end_hour', e.target.value)}
                                value={this.props.end.hour()}
                            >
                                {hours.map((h,i) => <MenuItem value={h} key={i}>{pad(h)}</MenuItem>)}
                            </Select>

                            <Select
                                style={{width:90}}
                                onChange={e => this.update('end_min', e.target.value)}
                                value={this.props.end.minute()}
                            >
                                {minutes.map((m,i) => <MenuItem value={m} key={i}>{pad(m)}</MenuItem>)}
                            </Select>
                            
                        </div>
                    </div> : null}

                    {this.props.depart !== null ? 
                    <div style={{display:'flex', flexDirection:'column', alignSelf:'stretch', padding:10, marginBottom:15}}>
                        <span className="hour-label">Departed</span>
                        <div style={{padding:10, display: 'flex', flexDirection:'row'}}>

                            <Select
                                style={{width:90, marginRight:15}}
                                onChange={e => this.update('depart_hour', e.target.value)}
                                value={this.props.depart.hour()}
                            >
                                {hours.map((h,i) => <MenuItem value={h} key={i}>{pad(h)}</MenuItem>)}
                            </Select>

                            <Select
                                style={{width:90}}
                                onChange={e => this.update('depart_min', e.target.value)}
                                value={this.props.depart.minute()}
                            >
                                {minutes.map((m,i) => <MenuItem value={m} key={i}>{pad(m)}</MenuItem>)}
                            </Select>
                            
                        </div>
                    </div> : null}
                </DialogContent>

                <DialogActions>
                    <div style={{alignSelf:'stretch', flex:1, flexDirection:'row', display:'flex', justifyContent:'space-between'}}>
                        <Button onClick={this.props.close} color="primary">
                            Cancel
                        </Button>
                        
                        <Button onClick={this.props.save} color="primary">
                            Save
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

}
