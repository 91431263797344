import React, { Component } from 'react';
import Slot from './slot/slot';
import moment from 'moment';

export default class NextUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next: null
        }
    }

    UNSAFE_componentWillReceiveProps(newprops) {
        this.find_next(newprops.slots);
    }

    componentDidMount() {
        this.find_next(this.props.slots);
    }

    find_next = (slots) => {
        let sortedArray = slots.filter(s => s.arrive_time === null && s.deleted !== true && s.block !== true && s.pre !== true).sort((a, b) => moment(a.original_date_time) - moment(b.original_date_time))
        if (sortedArray.length > 0) {
            this.setState({next: sortedArray[0]});
        } else {
            this.setState({next:null});
        }
    }

    render() {
        if (this.state.next === null) {
            return null;
        }
        return (
            <div className={`next-up ${this.props.vanish === true ? 'vanish' : ''}`}>
                <span className="title">
                    Next Up - {
                        moment.duration(moment(this.state.next.original_date_time).diff(moment())).humanize()
                        + (moment().isAfter(moment(this.state.next.original_date_time)) ? ' ago': '')}</span>
                <Slot view={() => this.props.view(this.state.next)} slot={this.state.next} />
            </div>
        );
    }
}