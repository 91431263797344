import { combineReducers } from 'redux';

import hauliers_reducer from './hauliers';
import slots_reducer from './slots';
import user_reducer from './users';
import notification_reducer from './notifications';

const rootReducer = combineReducers({
    hauliers_reducer,
    slots_reducer,
    user_reducer,
    notification_reducer,
});

export default rootReducer;