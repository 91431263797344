import { api } from '../../utils/api';

import {
    GET_HAULIERS,
    CREATE_HAULIER
} from '../action_list';


export const get_hauliers = (token, callback) => {
    return (dispatch) => {
        api({action:'get_hauliers', token: token}, (data) => { dispatch({type:GET_HAULIERS, hauliers: data.hauliers}); callback(); });
    }
}

export const create_haulier = (token, haulier, callback) => {
    return (dispatch) => {
        api({action:'create_haulier', haulier: haulier, token:token}, 
        (data) => {
            dispatch({type:CREATE_HAULIER, haulier: data.haulier});
            callback();
        });
    }
}
