import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { api_url } from '../../config';

const numtest = /\d/;
const uppercheck = /[A-Z]/;
const lowercheck = /[a-z]/;

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            old: "",
            new: "",
            confirm:"",
            new_errors:[],
            conf_error:true,
            new_touched: false,
            conf_touched: false,
            failed:false
        }
    }

    change_password = () => {
        if (this.state.conf_error === false && this.state.new_errors.length === 0 && this.state.old.length > 0) {
            fetch(api_url, {
                method:'POST',
                headers:{'Accept': 'application/json','Content-Type': 'application/json'},
                body:JSON.stringify({action:'change_password', token: this.props.token, new:this.state.new, old:this.state.old, confirm: this.state.confirm})
            }).then(resp => resp.json()).then(data => {
                if (data.success === true) {
                    this.setState({old:"", new:"", confirm:"", new_errors:[], conf_error:false, conf_touched:false, new_touched:false, failed:false}, () => this.props.close());
                } else {
                    this.setState({failed:true});
                }
            })
        }
    }

    validate_new = (nw) => {
        let errors = [];
        if (nw.length < 8) {
            errors.push(" > 8 Chars");
        }
        if (numtest.test(nw) === false) {
            errors.push("0-9");
        }
        if (uppercheck.test(nw) === false) {
            errors.push('A-Z');   
        }
        if (lowercheck.test(nw) === false) {
            errors.push('a-z');
        }
        this.setState({new_errors: errors, new:nw, new_touched:true});
    }

    validate_confirm = (conf) => {
        this.setState({conf_error: conf !== this.state.new, confirm:conf, conf_touched:true});
    }

    render() {
        return(
            <Dialog open={this.props.visible} onClose={this.props.close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change Your Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your old and new password below
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Old Password"
                        type="password"
                        fullWidth
                        value={this.state.old}
                        onChange={v => this.setState({old:v.target.value})}
                    />
                    <div style={{height:50, alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        
                    </div>

                    <TextField
                        margin="dense"
                        id="name"
                        label="New Password"
                        type="password"
                        fullWidth
                        value={this.state.new}
                        onChange={v => this.validate_new(v.target.value)}
                    />

                    <div style={{height:50, alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        {this.state.new_touched ? this.state.new_errors.map((e,i) => <span key={i} style={{display:'inline', color:'red', marginRight:20}}>{e}</span>) : null}
                    </div>

                    <TextField
                        margin="dense"
                        id="name"
                        label="Confirm Password"
                        type="password"
                        fullWidth
                        value={this.state.confirm}
                        onChange={v => this.validate_confirm(v.target.value)}
                    />
                    <div style={{height:50, alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        {this.state.conf_error && this.state.conf_touched ? <span style={{display:'inline', color:'red', marginRight:20}}>Must Match Password</span> : null}
                    </div>

                    <div style={{height:50, alignSelf:'stretch', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        {this.state.failed ? <span style={{display:'inline', color:'red', marginRight:20}}>Error</span> : null}
                    </div>

                </DialogContent>

                <DialogActions>
                    <Button onClick={this.props.close} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.change_password} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }

}
export default ChangePassword;