import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';

class YesNoModal extends Component {
    render() { 
        return (
            <Dialog onClose={this.props.close} aria-labelledby="simple-dialog-title" open={this.props.visible}>
                <DialogTitle id="simple-dialog-title">{this.props.title}</DialogTitle>
                <List>
                    
                    <ListItem button onClick={() => this.props.yes()}>
                        <ListItemAvatar>
                            <Avatar>
                                <Check />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Yes" />
                    </ListItem>

                    <ListItem button onClick={() => this.props.no()}>
                        <ListItemAvatar>
                            <Avatar>
                                <Close />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="No" />
                    </ListItem>
                   
                </List>
            </Dialog>
        );
    }
}

export default YesNoModal;