import React from 'react';
import ReactTimeout from 'react-timeout';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';

class DataLayer extends React.Component {
    check_for_notifications = () => {
        this.props.check_for_notifs(this.props.token);
    }

    check_for_notifications_loop = () => {
        this.check_for_notifications();
        this.props.setTimeout(this.check_for_notifications_loop, 30000);
    }

    check_for_updates = () => {
        let mm = 0;
        let max_block_mod = 0;
        if (this.props.slots.length > 0) {
            mm = Math.max(...this.props.slots.map(x => x.mod));
        }
        if (this.props.block_slots.length > 0) {
            max_block_mod = Math.max(...this.props.block_slots.map(x => x.mod));
            if (max_block_mod >= mm) {
                mm = max_block_mod;
            }
        }
        this.props.update_slots(this.props.token, mm);
    }

    check_for_updates_loop = () => {
        this.check_for_updates();
        this.props.setTimeout(this.check_for_updates_loop, 10000);
    }

    componentDidMount() {
        this.props.get_initial_notifications(this.props.token);
        this.props.setTimeout(this.check_for_notifications_loop, 30000);
        //this.props.get_slots(this.props.token);
        this.props.setTimeout(this.check_for_updates_loop, 10000);
    }

    render() {
        return this.props.children;
    }
}


function mapStateToProps(state, props) {
	return {
        logged_in: state.user_reducer.logged_in,
        token: state.user_reducer.token,
        max_mod: state.slots_reducer.max_mod,
        slots: state.slots_reducer.slots,
        block_slots: state.slots_reducer.block_slots
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default ReactTimeout(connect(mapStateToProps, mapDispatchToProps)(DataLayer));
