
import React, { Component } from 'react';
import moment from 'moment';
import FontAwesomeIcon from 'react-fontawesome';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Spinner from 'react-activity/lib/Spinner';
import Pre from './pre';
import Block from './block';
import 'react-activity/lib/Spinner/Spinner.css';
import { part_load_threshold } from '../../../config';

const tick = require('../../../assets/tick.png');
const excl = require('../../../assets/excl.png');

export default class Slot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slot: {
                original_date_time: null,
                haulier_name: "",
                payloads:[],
                direction:'inbound'
            }
        }
    }

    componentDidMount() {
        this.setState({slot: this.props.slot, tick:this.props.tick});
    }

    UNSAFE_componentWillReceiveProps(newprops) {
        this.setState({slot: newprops.slot, tick:this.props.tick});
    }

    render() {
        const { slot } = this.state;
        if (slot.pre === true) {
            return (<Pre search={this.props.search} tick={this.props.tick} view={this.props.view} slot={slot} />)
        }
        if (slot.block === true) {
            return (<Block search={this.props.search} tick={this.props.tick} view={this.props.view} slot={slot} />);
        }

        let className = 'slot'
        if (this.props.selected === true) {
            className = "slot selected";
        } else {
            if (slot.arrive_time === null && moment(slot.original_date_time).add(20, 'minutes').isBefore(moment(), 'minute')) {
                if (slot.pallets === null) {
                    className = "slot late pulsing-slot";
                } else {
                    if (slot.pallets >= part_load_threshold) {
                        className = "slot late pulsing-slot";
                    }
                }
            }
            if (slot.issues === true) {
                className = "slot issues";
            }
            if (slot.issues !== true && slot.depart_time !== null) {
                className = "slot noissues";
            }
            if (slot.deleted === true) {
                className = "slot deleted"
            }
        }
        if (this.props.className === 'top-slot') {
            className = className + " top-slot";
        }

        if (this.props.search === true) {
            className = className + " search-slot";
        }
        

        return (
            <div onClick={this.props.className === 'top-slot' ? () => {} : () => this.props.view()} className={className} style={slot.depart_time !== null ? {backgroundColor:'#05485e'} : null}>
                {slot.depart_time !== null ? <img alt="done" src={slot.issues === true ? excl : tick} /> : null}
                <div className="slot-top">
                   
                    <span>{slot.payloads ? slot.payloads.map(p => p.transport_id).join(' / ') : null}</span>
                    <div style={{flexDirection:'row'}}>
                        <FontAwesomeIcon name="pallet" icon="pallet" type="font-awesome" size={'lg'} color="#fff" />
                        <span className="pallet-count">({slot.pallets}P)</span>
                    </div>
                    {slot.direction === 'inbound' ? <ArrowDownward style={{fontSize:'1rem', color:'#000'}} /> : <ArrowUpward style={{fontSize:'1rem', color:'#fff'}} />}                 
                </div>
                <div className="slot-body">
                    {slot.haulier_name}
                    {slot.arrive_time !== null && slot.depart_time === null ? <div style={{position:'absolute', bottom:3, right:3}}><Spinner color="#fff" size={12} /></div> : null}
                </div>
            </div>
        );
    }
}
