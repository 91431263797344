import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

export default class IssuesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_issue: "SHORT_AGAINST_EXP",
            other_issue: "",
            comments: ""
        }
    }

    render() {
        return(
            <Dialog open={this.props.visible} onClose={() => this.props.close()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Report Issue</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select issue type
                    </DialogContentText>

                    <div style={{padding:10, display: 'flex', flexDirection:'column'}}>

                        <Select
                            autoWidth={true}
                            onChange={e => this.setState({selected_issue: e.target.value})}
                            value={this.state.selected_issue}
                        >
                            <MenuItem value="SHORT_AGAINST_EXP">Short Against Expected</MenuItem>
                            <MenuItem value="SHORT_SHELF_LIFE">Short Shelf Life</MenuItem>
                            <MenuItem value="DAMAGED_STOCK">Damaged Stock</MenuItem>
                            <MenuItem value="UNSAFE_LOAD">Unsafe to Tip</MenuItem>
                            <MenuItem value="OTHER">Other</MenuItem>
                        </Select>

                        {this.state.selected_issue === 'OTHER' 
                            ?
                        <TextField
                            id="OTHER_REASON"
                            label="Other Issue"
                            value={this.state.other_issue}
                            onChange={e => this.setState({other_issue: e.target.value})}
                            margin="normal"
                            />
                        : null}

                        <TextField
                            id="COMMENTS"
                            label="Comments"
                            value={this.state.comments}
                            onChange={e => this.setState({comments: e.target.value})}
                            margin="normal"
                            />
                        
                    </div>
                </DialogContent>

                <DialogActions>
                    <div style={{alignSelf:'stretch', flex:1, flexDirection:'row', display:'flex', justifyContent:'space-between'}}>
                        <Button onClick={this.props.close} color="primary">
                            Cancel
                        </Button>
                        
                        <Button onClick={() => this.props.save(this.state.selected_issue === 'OTHER' ? this.state.other_issue : this.state.selected_issue, this.state.comments)} color="primary">
                            Save
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

}
