import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ErrorModal extends React.Component {
    render() { 
        return (
            <Dialog
                open={this.props.visible}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Invalid Booking</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.props.error}
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>

                <Button onClick={this.props.close} color="primary">
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ErrorModal;