import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import ChangePassword from '../modals/change_password';
import EmailAppLink from '../modals/app_email_link';
import Spinner from 'react-activity/lib/Spinner';
import { api_url } from '../../config';
import 'react-activity/lib/Spinner/Spinner.css';
import ReportModal from '../modals/reports_modal';


class LeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            change_password: false,
            app_link: false,
            app_loading: false,
            reports_modal: false
        }
    }

    get_app_link = () => {
        this.setState({app_loading: true})
        fetch(api_url, {
            method: 'POST',
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'},
            body:JSON.stringify({token: this.props.token, action:'email_app_link'})
        }).then(
                data => data.json()
            ).then(
                data => {
                    if (data.success === true) {
                        this.setState({app_link: true, app_loading: false});
                    }
                }
            );
    }

    render() {
        return (
            <div className={`left-menu ${this.props.vanish === true ? 'vanish' : ''}`}>
                <ChangePassword token={this.props.token} visible={this.state.change_password} close={() => this.setState({change_password:false})} />
                <EmailAppLink visible={this.state.app_link} close={() => this.setState({app_link: false})}  />
                <ReportModal token={this.props.token} visible={this.state.reports_modal} close={() => this.setState({reports_modal: false})} />
                <div style={{alignSelf:'stretch', display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <Button className="menu-button" onClick={() => this.props.showMiddleLeft('new_booking')} variant="contained" style={{backgroundColor:'#119100', color:'#fff'}}>Create Booking</Button>
                    <Button color="primary" className="menu-button" onClick={() => this.props.showMiddleLeft('new_block_booking')} variant="contained">Block Booking</Button>
                    <Button color="primary" className="menu-button" onClick={() => this.props.showMiddleLeft('other_jobs')} variant="contained">Other Jobs</Button>
                    <Button color="primary" className="menu-button" onClick={() => this.setState({reports_modal: true})} variant="contained">Reports</Button>
                    {this.props.account_type !== 'HAULIER' ? <Button color="primary" className="menu-button" onClick={() => this.props.showMiddleLeft('new_haulier')} variant="contained">Create Haulier</Button> : null}
                </div>

                <div style={{alignSelf:'stretch', display:'flex', flexDirection:'column', alignItems:'center'}}>
                    {this.state.app_loading === true ? <Spinner color="red" size={12} /> : <Button onClick={() => this.get_app_link()}>Email Android App Link</Button>}
                    <Button onClick={() => this.setState({change_password: true})}>Change Password</Button>
                    <Button onClick={() => this.props.logout()}>Logout</Button>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
	return {
        token: state.user_reducer.token,
        account_type: state.user_reducer.account_type
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);