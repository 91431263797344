import {
    LOGIN,
    LOGIN_START,
    LOGIN_FAIL,
    LOGOUT,
    SET_PASS,
    RESET_PASS,
    PAUSE_BACKGROUND,
    SET_WALLPAPER,
    TOGGLE_WALLPAPER_MODE,
    SET_NOTIFICATION_PREFS,
    VIEW_BACKGROUND,
    UN_VIEW_BACKGROUND
} from '../action_list';

let user_state = {
    logged_in: false,
    login_error: false,
    logging_in: false,
    token: null,
    must_set_pass: false,
    name: null,
    reset_message: false,
    background_paused: false,
    id: null,
    wallpaper: null,
    wallpaper_mode: 'scene',
    account_type: null,
    wallpaper_changed_at_login: false,
    notification_preferences: {},
    viewing_background:false
};

const user_reducer = (state=user_state, action) => {
    switch(action.type) {
        case VIEW_BACKGROUND:
            state = Object.assign({}, state, {viewing_background: true});
            return state;
        
        case UN_VIEW_BACKGROUND:
            state = Object.assign({}, state, {viewing_background: false});
            return state;

        case SET_NOTIFICATION_PREFS:
            state = Object.assign({}, state, {notification_preferences: action.preferences});
            return state;

        case TOGGLE_WALLPAPER_MODE:
            state = Object.assign({}, state, {wallpaper_mode: action.mode});
            if (state.logged_in === false) {
                state = Object.assign({}, state, {wallpaper_changed_at_login: true});
            }
            return state;
            
        case SET_WALLPAPER:
            state = Object.assign({}, state, {wallpaper: action.wallpaper});
            return state;

        case PAUSE_BACKGROUND:
            state = Object.assign({}, state, {background_paused: !state.background_paused});
            return state;

        case SET_PASS:
            state = Object.assign({}, state, {must_set_pass:false});
            return state;

        case LOGOUT:
            return user_state;

        case LOGIN:
            if (action.wallpaper_changed_at_login === false) {
                state = Object.assign({}, state, {wallpaper_mode: action.background_style});
            }

            state = Object.assign({}, state, {notification_preferences: action.notification_preferences, account_type: action.account_type, logged_in:true, token: action.token, logging_in: false, login_error: false, name: action.name, must_set_pass: action.must_set_pass, id: action.user_id});
            
            return state;

        case LOGIN_START:
            state = Object.assign({}, state, {logged_in: false, logging_in: true, login_error: false});
            return state;

        case LOGIN_FAIL:
            state = Object.assign({}, state, {logged_in:false, logging_in: false, login_error: true});
            return state;

        case RESET_PASS:
            state = Object.assign({}, state, {reset_message: true});
            return state;
        
        default:
            return state;
    }
}

export default user_reducer;