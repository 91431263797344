import { api } from '../../utils/api';

import {
    NEW_NOTIFICATIONS,
    GET_INIT_NOTIFS,
    SET_NOTIFICATION_PREFS
} from '../action_list';

export const check_for_notifs = (token) => {
    return (dispatch) => {
        api({action: 'check_for_notifs', token:token, mobile:false},
        (data) => {
            if (data.success === true) {
                dispatch({type: NEW_NOTIFICATIONS, notifications: data.notifications});
            }
        });
    }
}

export const get_initial_notifications = (token) => {
    return (dispatch) => {
        api({action: 'get_initial_notifications', token:token},
        (data) => {
            if (data.success === true) {
                dispatch({type: GET_INIT_NOTIFS, notifications: data.notifications});
            }
        });
    }
}

export const set_preferences = (token, preferences) => {
    return (dispatch) => {
        api({action: 'set_preferences', token: token, notification_preferences: preferences},
        (data) => {
            dispatch({type: SET_NOTIFICATION_PREFS, preferences: preferences});
        })
    }
}