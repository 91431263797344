import React, { Component } from 'react';


export default class SlotBlock extends Component {
    render() {
        let className = 'slot block';
        if (this.props.slot.deleted === true) {
            className = 'slot deleted';
        }

        if (this.props.search === true) {
            className = className + " search-slot";
        }


        return (
            <div onClick={() => this.props.view()} className={className}>
                <div className="slot-top">
                </div>
                <div className="slot-body">
                    <span>Block Booking for {this.props.slot.haulier_name}</span>
                </div>
            </div>
        );
    }
}
