import {
    NEW_NOTIFICATIONS,
    GET_INIT_NOTIFS,
    LOGOUT,
    PLAYED_SOUND
} from '../action_list';

let notification_state = {
    notifications: [],
    ticker: 0,
    play_sound: false
}

let ns;

const notification_reducer = (state=notification_state, action) => {
    switch(action.type) {
        case LOGOUT:
            return notification_state;

        case PLAYED_SOUND:
            state = Object.assign({}, state, {play_sound:false});
            return state;
            
        case NEW_NOTIFICATIONS:
            ns = state.notifications;
            if (action.notifications.length > 0) {
                state = Object.assign({}, state, { play_sound: true });
            }
            ns = action.notifications.concat(ns);
            state = Object.assign({}, state, {notifications: ns, ticker:state.ticker + 1});
            return state;
            
        case GET_INIT_NOTIFS:
            state = Object.assign({}, state, {notifications: action.notifications, ticker: state.ticker + 1});
            return state;

        default:
            return state;
    }
}

export default notification_reducer;