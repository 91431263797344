import React, { Component } from 'react';
import Sound from 'react-sound';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';


class NotifySound extends Component {
    render() {
        if (this.props.play_sound === true) {
            return (
                <Sound
                    url="notify.mp3"
                    playStatus={Sound.status.PLAYING}
                    playFromPosition={0}
                    onFinishedPlaying={() => this.props.played_sound()}
                    />
            );
        }
        return null;

    }
}

function mapStateToProps(state) {
	return {
        play_sound: (state.slots_reducer.play_sound === true || state.notification_reducer.play_sound === true)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifySound);
