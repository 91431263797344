import { api_url } from '../config';

export const api = (data, cb) => {
    
    data.medium = "BROWSER";
    fetch(api_url, {
        method:'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body:JSON.stringify(data)
    }).then(resp => {
        return resp.json();
    }
    ).then(resp => {
        cb(resp)
    });
}