import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import YesNoModal from '../modals/yes_no_modal';
import ErrorModal from '../modals/error_modal';

const pad = n => {
    n = n.toString();
    while (n.length < 2) {
        n = '0' + n;
    }
    return n;
}

const days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
const hours = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'];


class BlockBookingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:null,
            new:true,
            Monday:false,
            Tuesday:false,
            Wednesday:false,
            Thursday:false,
            Friday:false,
            Saturday:false,
            Sunday:false,
            from:'10:00',
            to: '11:00',
            available_from: hours,
            available_to: hours,
            haulier:this.props.hauliers[0],
            deleting: false,
            error: null
        };
    }

    componentDidMount() {
        let bl = this.props.edit_block;
        if (bl !== null) {
            let st = {
                id: bl.id,
                new: false,
                Monday: bl.block_days.indexOf('Monday') !== -1,
                Tuesday: bl.block_days.indexOf('Tuesday') !== -1,
                Wednesday: bl.block_days.indexOf('Wednesday') !== -1,
                Thursday: bl.block_days.indexOf('Thursday') !== -1,
                Friday: bl.block_days.indexOf('Friday') !== -1,
                Saturday: bl.block_days.indexOf('Saturday') !== -1,
                Sunday: bl.block_days.indexOf('Sunday') !== -1,
                from: pad(bl.block_from) + ':00',
                to: pad(bl.block_to) + ':00',
                haulier: bl.haulier.id,
            }
            let avail_to_ind = hours.indexOf(bl.from);
            let avail_from_ind = hours.indexOf(bl.to);
            let avail_to = hours.slice(avail_to_ind + 1);
            let avail_from = hours.slice(0, avail_from_ind);
            st.available_from = avail_from;
            st.available_to = avail_to;

            this.setState(st);
        }
    }



    submit = () => {
        let block = Object.assign({}, this.state, {available_from: undefined, available_to: undefined, deleting:undefined});
        this.props.create_block_booking(this.props.token, block, this.state.new,
            () => this.setState({
                Monday:false,
                Tuesday:false,
                Wednesday:false,
                Thursday:false,
                Friday:false,
                Saturday: false,
                Sunday:false,
                from:'10:00',
                to:'11:00'
            }, () => this.props.cancel()));
    }

    handleFrom = (from) => {
        let hr = parseInt(from.split(':')[0]);
        if ((hr < 6 || hr > 16) && this.props.account_type === 'HAULIER') {
            this.setState({error: 'Please contact Albany to request a slot outside of normal hours (06:00 - 14:00)'})
            return;
        }
        let i = hours.indexOf(from) + 1;
        this.setState({from: from, available_to: hours.slice(i)});
    }

    handleTo = (to) => {
        let hr = parseInt(to.split(':')[0]);
        if ((hr < 6 || hr > 16) && this.props.account_type === 'HAULIER') {
            this.setState({error: 'Please contact Albany to request a slot outside of normal hours (06:00 - 14:00)'})
            return;
        }
        let i = hours.indexOf(to);
        this.setState({to: to, available_from: hours.slice(0,i)})
    }

    handleDay = d => {
        let s = this.state;
        s[d] = !s[d];
        this.setState(s);
    }

    delete = () => {
        this.setState({deleting:true});
    }


    render() {
        return (
            <div>
                <ErrorModal visible={this.state.error !== null} error={this.state.error} close={() => this.setState({error:null})} />
                <YesNoModal title="Delete Block Booking?" visible={this.state.deleting} yes={() => this.props.delete_slot(this.props.token, this.state.id, true, () => this.setState({deleting:false}, () => this.props.cancel()))} no={() => this.setState({deleting:false})} />
                <div className="block-booking-form box">
                    <div style={{flexDirection:'row', alignItems:'center'}}>
                        <CloseIcon onClick={this.props.cancel} style={{fontSize:29, position:'relative', top:7, marginRight:10, color:'#000'}} />
                        <span className="heading">{this.state.new === true ? 'Create a' : 'Edit'} Block Booking</span>
                    </div>


                    <div style={{flex:1}}>
                        <div className="form-area">
                            {days.map((d,i) => 
                                <FormControlLabel
                                    key={i}
                                    control={
                                    <Checkbox
                                        checked={this.state[d]}
                                        onChange={() => this.handleDay(d)}
                                        value={d+'-check'}
                                        color="primary"
                                        />
                                    }
                                    label={d}
                                    style={{color:'#000'}}
                                    />
                            )}
                        </div>

                        <div className="form-area">
                            <div style={{flexDirection:'row', display:'flex', alignSelf:'stretch', justifyContent:'space-evenly'}}>
                                <InputLabel htmlFor="from-select">From</InputLabel>
                                <Select
                                    value={this.state.from}
                                    onChange={(v) => this.handleFrom(v.target.value)}
                                    inputProps={{
                                        name: 'from-select',
                                        id: 'from-select',
                                    }}
                                    style={{width:250}}
                                    >
                                        {this.state.available_from.map((h,i) => <MenuItem value={h} key={i}>{h}</MenuItem>)}
                                </Select>


                                <InputLabel htmlFor="to-select">To</InputLabel>
                                <Select
                                    value={this.state.to}
                                    onChange={(v) => this.handleTo(v.target.value)}
                                    inputProps={{
                                        name:'to-select',
                                        id: 'to-select',
                                    }}
                                    style={{width:250}}
                                    >
                                        {this.state.available_to.map((h,i) => <MenuItem value={h} key={i}>{h}</MenuItem>)}
                                </Select>
                            </div>
                        </div>


                        <div className="form-area">
                            <InputLabel htmlFor="haulier">Haulier</InputLabel>
                            <Select
                                value={this.state.haulier}
                                onChange={(v) => this.setState({haulier: v.target.value})}
                                inputProps={{
                                    name: 'age',
                                    id: 'haulier',
                                }}
                                style={{width:250}}
                                >
                                    {this.props.hauliers.map((h,i) => <MenuItem value={h.id} key={i}>{h.name}</MenuItem>)}
                            </Select>
                        </div>
                    </div>

                    <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'space-between', padding:20}}>
                        <Button onClick={() => this.props.cancel()}>Cancel</Button>
                        {this.state.new !== true ? <Button variant="contained" color="red" onClick={() => this.delete()}>Delete</Button> : null}
                        <Button variant="contained" color="primary" onClick={() => this.submit()}>Save</Button>
                    </div>
                </div>
            </div>
        );    
    }
}

function mapStateToProps(state, props) {
	return {
        hauliers: state.hauliers_reducer.hauliers,
        slot: state.slots_reducer.slot_in_view,
        edit_block: state.slots_reducer.edit_block,
        token: state.user_reducer.token,
        account_type: state.user_reducer.account_type
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockBookingForm);