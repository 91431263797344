import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class EmailAppLink extends Component {
    render() {
        return(
            <Dialog open={this.props.visible} onClose={() => this.props.close()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You will receive a link by email
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <div style={{alignSelf:'stretch', flex:1, flexDirection:'row', display:'flex', justifyContent:'flex-end'}}>
                        <Button onClick={this.props.close} color="primary">
                            OK
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

}
