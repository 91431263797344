import React, { Component } from 'react';
import moment from 'moment'

class Welcome extends Component {
    getWelcome = (name) => {
        let nw = moment().hour();
        if (nw > -1 && nw < 12) {
            return 'Good Morning '+name;
        } else if (nw >= 12 && nw < 17) {
            return 'Good Afternoon ' +name;
        } else {
            return 'Good Evening '+name;
        }
    }

    render() {
        return (
            <div {...this.props}>
                {this.getWelcome(this.props.children.split(' ')[0])}
            </div>
        );
    }
}

export default Welcome;