import moment from 'moment';

import {
    GET_SLOTS,
    CREATE_SLOT,
    EDIT_SLOT,
    RESET_REFRESH,
    UPDATE_SLOT_STATUS,
    VIEW_SLOT,
    CREATE_BLOCK_SLOT,
    VIEW_BLOCK_SLOT,
    DELETE_SLOT,
    LOGOUT,
    UPDATE_SLOTS,
    PLAYED_SOUND,
    SEARCH,
    SLOT_AVAILABLE,
    LOGIN,
    SEARCH_OTHER
} from '../action_list';

let slots_state = {
    slots: [],
    organised_slots: {},
    ticker: 0,
    refresh: false,
    slot_in_view: null,
    block_slots: [],
    edit_block: null,
    max_mod: null,
    play_sound: false,
    max_date: moment().add(7, 'days'),
    min_date: moment().subtract(7, 'days'),
    min_hour: 6,
    max_hour: 18,
    search_term: null,
    search_results: [],
    unavailable: [],
    other_jobs: [],
    search_results_other: [],
    search_term_other: null
};

let slots;
let i;
let organised_slots;
let block_slots;
let new_slots;
let bs;
let min_max;
let results;
let unavailable;
let other_results;

const get_max_date = slots => {
    const dates = slots.map(x => new Date(x.original_date_time));
    let maxDate;
    if (dates.length > 0) {
        maxDate = moment(new Date(Math.max.apply(null, dates))).add(2, 'days');
        while (maxDate.day() !== 1) {
            maxDate = maxDate.add(1, 'days');
        }
    } else {
        maxDate = moment().add(7, 'days');
    }
    if (maxDate.isBefore(moment(), 'days')) {
        maxDate = moment();
    }
    return maxDate;
}


const get_min_date = slots => {
    let dates = slots.map(x => new Date(x.original_date_time));
    dates = dates.filter(x => moment(x).isAfter(moment().subtract(21, 'days')));
    let minDate;
    if (dates.length > 0) {
        minDate = moment(new Date(Math.min.apply(null, dates))).subtract(1, 'days');
    } else {
        minDate = moment().subtract(7, 'days');
    }
    if (minDate.isAfter(moment(), 'days')) {
        minDate = moment();
    }
    return minDate;
}


const get_min_max_hour = slots => {
    let min_hour = 6;
    let max_hour = 14;
    if (slots.length > 0) {
        let slot_hours = slots.map(s => moment(s.original_date_time).hour());
        let min_in_slots = Math.min(...slot_hours);
        if (min_in_slots < min_hour) {
            min_hour = min_in_slots;
        }
        let max_in_slots = Math.max(...slot_hours);
        if (max_in_slots > max_hour) {
            max_hour = max_in_slots;
        }
    }
    return { min_hour: min_hour, max_hour: max_hour };
}

const organise_slots = (slots) => {
    let output = {};
    let dt;
    let hr;
    let mmt;

    for (i = 0; i < slots.length; i++) {
        mmt = moment(slots[i].original_date_time).tz('Europe/London');
        dt = mmt.format("YYYY-MM-DD");
        hr = slots[i].original_date_time.split('T')[1].split(':')[0];
        hr = parseInt(hr);

        console.log(hr, slots[i].original_date_time);

        if (!(dt in output)) {
            output[dt] = { 'Before 06:00': [], 'After 18:00': [] };
        }
        if (hr < 6) {
            output[dt]['Before 06:00'].push(JSON.parse(JSON.stringify(slots[i])))
        } else {
            if (!(hr in output[dt])) {
                output[dt][hr] = [];
            }
            output[dt][hr].push(JSON.parse(JSON.stringify(slots[i])));
        }
    }
    return output;
}


const filter_new_slots = (slots, new_slot) => {
    return slots.filter(x => x.id !== new_slot.id);
}

const search_slots = (slots, term) => {
    let output = [];
    for (var i = 0; i < slots.length; i++) {
        for (var j = 0; j < slots[i].payloads.length; j++) {
            if (slots[i].payloads[j].transport_id.toString().indexOf(term) !== -1) {
                output.push(slots[i]);
            }
        }
    }
    return output;
}

const search_other = (slots, term) => {
    let output = [];
    for (var i = 0; i < slots.length; i++) {
        if (slots[i].ref.toString().indexOf(term) !== -1) {
            output.push(slots[i]);
        }
    }
    return output;
}


const slots_reducer = (state = slots_state, action) => {
    switch (action.type) {
        case SEARCH_OTHER:
            if (action.term === null) {
                other_results = [];
            } else {
                other_results = search_other(state.other_jobs, action.term);
            }

            state = Object.assign({}, state, { search_term_other: action.term, search_results_other: other_results });
            return state;


        case SLOT_AVAILABLE:
            unavailable = state.unavailable;
            if (unavailable.indexOf(action.date_hour) === -1) {
                unavailable.push(action.date_hour);
            } else {
                unavailable = unavailable.filter(x => x !== action.date_hour);
            }
            state = Object.assign({}, state, { unavailable: unavailable });
            return state;

        case SEARCH:
            if (action.term === null) {
                results = [];
            } else {
                results = search_slots(slots, action.term);
            }

            state = Object.assign({}, state, { search_term: action.term, search_results: results });
            return state;

        case PLAYED_SOUND:
            state = Object.assign({}, state, { play_sound: false });
            return state;

        case UPDATE_SLOTS:
            new_slots = action.slots.filter(x => x.block !== true);
            block_slots = action.slots.filter(x => x.block === true);

            slots = state.slots;
            bs = state.block_slots;

            if (new_slots.length > 0) {
                for (i = 0; i < new_slots.length; i++) {
                    slots = filter_new_slots(slots, new_slots[i]);
                    slots.push(JSON.parse(JSON.stringify(new_slots[i])));
                }

                organised_slots = organise_slots(slots);

                min_max = get_min_max_hour(slots);

                state = Object.assign({}, state, {
                    max_date: get_max_date(slots),
                    min_date: get_min_date(slots),
                    slots: slots,
                    organised_slots: organised_slots,
                    refresh: true,
                    ticker: state.ticker + 1,
                    max_mod: action.max_mod,
                    play_sound: true,
                    min_hour: min_max.min_hour,
                    max_hour: min_max.max_hour
                });
            }

            if (block_slots.length > 0) {
                for (i = 0; i < block_slots.length; i++) {
                    bs = filter_new_slots(bs, block_slots[i]);
                    bs.push(JSON.parse(JSON.stringify(block_slots[i])));
                    state = Object.assign({}, state, { block_slots: bs, refresh: true, ticker: state.ticker + 1, play_sound: true });
                }
            }

            return state;

        case LOGOUT:
            return slots_state;

        case DELETE_SLOT:
            if (action.block === true) {
                let blocks = state.block_slots;
                blocks = blocks.filter(x => x.id !== action.slot.id);
                blocks.push(action.slot);
                state = Object.assign({}, state, { block_slots: blocks, slot_in_view: action.slot, max_mod: action.slot.mod });
            } else {
                slots = state.slots.filter(s => s.id !== action.slot.id);
                slots.push(action.slot);
                organised_slots = organise_slots(slots);

                min_max = get_min_max_hour(slots);

                state = Object.assign({}, state, {
                    max_date: get_max_date(slots),
                    min_date: get_min_date(slots),
                    max_mod: action.slot.mod,
                    slots: slots,
                    organised_slots: organised_slots,
                    slot_in_view: action.slot,
                    ticker: state.ticker + 1,
                    refresh: true,
                    min_hour: min_max.min_hour,
                    max_hour: min_max.max_hour
                });
            }
            return state;


        case VIEW_BLOCK_SLOT:
            state = Object.assign({}, state, { edit_block: action.slot });
            return state

        case CREATE_BLOCK_SLOT:
            block_slots = state.block_slots;
            if (action.nw !== true) {
                for (let i = 0; i < block_slots.length; i++) {
                    if (block_slots[i].id === action.slot.id) {
                        block_slots.splice(i, 1, action.slot);
                        break;
                    }
                }

            } else {
                block_slots.push(action.slot);
            }

            min_max = get_min_max_hour(slots);

            state = Object.assign({}, state, {
                max_date: get_max_date(block_slots),
                min_date: get_min_date(slots),
                block_slots: block_slots,
                ticker: state.ticker + 1,
                refresh: true,
                min_hour: min_max.min_hour,
                max_hour: min_max.max_hour
            });
            return state;


        case VIEW_SLOT:
            state = Object.assign({}, state, { slot_in_view: action.slot });
            return state;

        case GET_SLOTS:
            console.log("GET SLOTS");
            slots = action.slots.filter(x => x.block !== true);
            block_slots = action.slots.filter(x => x.block === true);
            organised_slots = organise_slots(slots);

            min_max = get_min_max_hour(slots);


            state = Object.assign({}, state, {
                max_date: get_max_date(slots),
                min_date: get_min_date(slots),
                slots: slots,
                organised_slots: organised_slots,
                block_slots: block_slots,
                ticker: state.ticker + 1,
                refresh: true,
                max_mod: action.max_mod,
                min_hour: min_max.min_hour,
                max_hour: min_max.max_hour,
                other_jobs: action.other_jobs
            });
            return state;

        case EDIT_SLOT:
            slots = state.slots.filter(x => x.id !== action.slot.id);
            slots.push(JSON.parse(JSON.stringify(action.slot)));
            organised_slots = organise_slots(slots);

            min_max = get_min_max_hour(slots);

            state = Object.assign({}, state, {
                max_date: get_max_date(slots),
                min_date: get_min_date(slots),
                max_mod: action.slot.mod,
                slots: slots,
                organised_slots: organised_slots,
                ticker: state.ticker + 1,
                refresh: true,
                min_hour: min_max.min_hour,
                max_hour: min_max.max_hour
            });

            if (state.slot_in_view !== null) {
                if (state.slot_in_view.id === action.slot.id) {
                    state = Object.assign({}, state, { slot_in_view: action.slot });
                }
            }

            return state;

        case CREATE_SLOT:
            slots = state.slots;
            slots.push(JSON.parse(JSON.stringify(action.slot)));
            organised_slots = organise_slots(slots);

            min_max = get_min_max_hour(slots);

            state = Object.assign({}, state, {
                max_date: get_max_date(slots),
                min_date: get_min_date(slots),
                max_mod: action.slot.mod,
                organised_slots: organised_slots,
                slots: slots,
                ticker: state.ticker + 1,
                refresh: true,
                min_hour: min_max.min_hour,
                max_hour: min_max.max_hour
            });
            return state;

        case UPDATE_SLOT_STATUS:
            slots = state.slots;
            for (i = 0; i < slots.length; i++) {
                if (slots[i].id === action.id) {
                    slots.splice(i, 1, action.slot);
                    break;
                }
            }

            organised_slots = organise_slots(slots);

            if (state.slot_in_view !== null) {
                if (state.slot_in_view.id === action.slot.id) {
                    state = Object.assign({}, state, { slot_in_view: action.slot });
                }
            }

            min_max = get_min_max_hour(slots);

            state = Object.assign({}, state, {
                max_date: get_max_date(slots),
                min_date: get_min_date(slots),
                min_max_mod: action.slot.mod,
                slots: slots,
                organised_slots: organised_slots,
                ticker: state.ticker + 1,
                min_hour: min_max.min_hour,
                max_hour: min_max.max_hour
            });

            return state;

        case RESET_REFRESH:
            state = Object.assign({}, state, { refresh: false });
            return state;

        case LOGIN:
            state = Object.assign({}, state, { unavailable: action.unavailable_dates });
            return state;

        default:
            return state;
    }
}

export default slots_reducer;