import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import ReactTimeout from 'react-timeout';
import SlotHour from './slot/slot_hour';
import { holidays } from '../../config';

const hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
const hours_with_null = [null, 0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];

const pad = (h) => {
    let x = h.toString();
    while (x.length < 2) {
        x = '0' + x;
    }
    return x;
}

const is_holiday = day => {
    return holidays.indexOf(day.format('YYYY-MM-DD')) !== -1;
}


class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            now: null,
            days:[],
            ticker:0,
            slots:{},
            unavailable:[]
        }
    }

    is_unavailable = (day, hour) => {
        if (day.isBefore(moment(), 'days')) {
            return true;
        }
        if ([8,9,10].indexOf(hour) !== -1 && day === 1) {
            return false;
        }
        if (hour < 6) {
            return true;
        }
        if (hour > 14) {
            return true;
        }

        if (day.day() === 0) {
            if ([8,9,11,12].indexOf(hour) === -1) {
                return true;
            }
            return false;
        }

        if (day.day() === 6) {
            return true
        }

        let date_hour = day.format('YYYY-MM-DD') + '_' + hour;
        if (this.props.unavailable.indexOf(date_hour) !== -1) {
            return true;
        }
        return false;
    }

    toggle_available = (d,h) => {
        let date_hour = d.format('YYYY-MM-DD') + '_' + h;
        this.props.toggle_slot_available(this.props.token, date_hour);
    }

    headingStyle = h => {
        if (h === null) {
            return 'width-split calendar-heading day-column';
        } 

        let s;
        if (h === this.state.now) {
            s = 'heading-now';
        } else if (h > this.state.now) {
            s = 'heading-future';
        } else {
            s = 'heading-past';
        }
        return 'width-split calendar-heading ' + s;
    }


    UNSAFE_componentWillReceiveProps(newprops) {
        if (newprops.refresh === true) {
            this.setState({slots: newprops.slots});
            this.props.reset_refresh();
        }
        if (newprops.max_date !== this.props.max_date) {
            this.setState({days: this.set_date_range(newprops.min_date, newprops.max_date)});
        }
    }

    set_date_range = (min_date, max_date) => {
        let dates = [];
        let c = min_date;
        while (c.isSameOrBefore(max_date)) {
            dates.push(moment(c.format()));
            c = c.add(1,'days');
        }
        return dates;
    }


    setCalendarLocation = async () => {
        this.props.setTimeout(() => {
            try {
                let today = document.querySelector('#today');
                today.scrollIntoView({behavior:'smooth', block:'nearest', inline:'start'});
            } catch (e) {}
            }, 2000)
    }

    componentDidMount() {
        let hour = moment().hour();
        this.setState({now:hour, days: this.set_date_range(this.props.min_date, this.props.max_date), slots:this.props.slots});
        this.props.setTimeout((this.setCalendarLocation), 800);

    }

    render() {
        console.log(this.props.refresh);
        let style = {};
        if (this.props.full === false) {
            style = {maxWidth: '98vw'};
        }

        return (
            <div className={`box ${this.props.menu_is_hidden === true ? 'menu_is_hidden' : 'menu_is_showing'}`} style={style}>
                <div id="cal" className="calendar-main" style={{position:'relative'}}>
                    <table className="calendar-table table-cal">
                        <thead className="thead-cal">
                            <tr className="tr-cal">
                                <th className="day-column"></th>
                                <th id="no-slot-hour" className="th-cal not-first"><div className="heading-past calendar-heading">Part Loads</div></th>
                                {
                                    hours_with_null.filter(h => h !== null && (h >= this.props.min_hour && h <= this.props.max_hour)).map((h,i) => <th ref={ref => { if (h === 6) { this.six = ref}}} key={i} id={"hour-"+h} className='th-cal not-first'><div className={this.headingStyle(h)}>{h !== null ? pad(h)+':00' : null}</div></th>)}
                            </tr>
                        </thead>

                        <tbody className="tbody-cal">
                            {this.state.days.map((d,i) => 
                                <tr className={`datra tr-cal ${d.day() === 6 || d.day() === 0 || is_holiday(d) ? 'weekend' : ''}`} key={i}>
                                    <td 
                                        id={d.isSame(moment(), "day") ? 'today' : null} 
                                        className={`td-cal date-col-cell day-column`}>
                                        <div className={`date-col-cell-inner ${d.isBefore(moment(), 'day') ? 'heading-past' : ""} ${moment().isBefore(d, 'day') ? 'heading-future' : ''}`}>
                                            {d.format("ddd, MMMM Do")}
                                        </div>
                                    </td>
                                    <td className="td-cal calendar-cell part-load">
                                        <SlotHour
                                            selected={this.props.selected}
                                            tick={this.props.ticker}
                                            view={(slot) => this.props.view(slot)}
                                            weekday={d.day()}
                                            day={d.format("YYYY-MM-DD")}
                                            hour={"SMALL"}
                                            slots={this.state.slots}
                                            block_slots={this.props.block_slots} />
                                    </td>

                                    {hours.filter(h => h >= this.props.min_hour && h <= this.props.max_hour).map((h,i) =>
                                        <td
                                            onDoubleClick={this.props.account_type === 'ARCHBOLD' || this.props.name === "Mike Saggerson" ? () => this.toggle_available(d,h) : () => {}}
                                            key={i}
                                            className={`td-cal calendar-cell ${this.is_unavailable(d, h) ? 'unavailable-hour' : ''}`}>
                                                <SlotHour
                                                    selected={this.props.selected}
                                                    tick={this.props.ticker}
                                                    view={(slot) => this.props.view(slot)}
                                                    weekday={d.day()}
                                                    day={d.format("YYYY-MM-DD")}
                                                    hour={h}
                                                    slots={this.state.slots}
                                                    block_slots={this.props.block_slots} />
                                        </td>)}
                                </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
	return {
        slots: state.slots_reducer.organised_slots,
        ticker: state.slots_reducer.ticker,
        refresh: state.slots_reducer.refresh,
        block_slots: state.slots_reducer.block_slots,
        max_date: state.slots_reducer.max_date,
        min_date: state.slots_reducer.min_date,
        min_hour: state.slots_reducer.min_hour,
        max_hour: state.slots_reducer.max_hour,
        unavailable: state.slots_reducer.unavailable,
        account_type: state.user_reducer.account_type,
        token: state.user_reducer.token,
        name: state.user_reducer.name
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default ReactTimeout(connect(mapStateToProps, mapDispatchToProps)(Calendar));