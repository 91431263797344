export const GET_HAULIERS = 'GET_HAULIERS';
export const CREATE_HAULIER = 'CREATE_HAULIER';
export const GET_SLOTS = 'GET_SLOTS';
export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CREATE_SLOT = 'CREATE_SLOT';
export const EDIT_SLOT = 'EDIT_SLOT';
export const RESET_REFRESH = 'RESET_REFRESH';
export const UPDATE_SLOT_STATUS = 'UPDATE_SLOT_STATUS';
export const VIEW_SLOT = 'VIEW_SLOT';
export const NEW_NOTIFICATIONS = 'NEW_NOTIFICATIONS';
export const GET_INIT_NOTIFS = 'GET_INIT_NOTIFS';
export const CREATE_BLOCK_SLOT = 'CREATE_BLOCK_SLOT';
export const VIEW_BLOCK_SLOT = 'VIEW_BLOCK_SLOT';
export const DELETE_SLOT = 'DELETE_SLOT';
export const LOGOUT = 'LOGOUT';
export const CHANGE_PASS_START = 'CHANGE_PASS_START';
export const CHANGE_PASS = 'CHANGE_PASS';
export const SET_PASS = 'SET_PASS';
export const RESET_PASS = 'RESET_PASS';
export const UPDATE_SLOTS = 'UPDATE_SLOTS';
export const PAUSE_BACKGROUND = 'PAUSE_BACKGROUND';
export const PLAYED_SOUND = 'PLAYED_SOUND';
export const SET_WALLPAPER = 'SET_WALLPAPER';
export const TOGGLE_WALLPAPER_MODE = 'TOGGLE_WALLPAPER_MODE';
export const SEARCH = 'SEARCH';
export const SET_NOTIFICATION_PREFS = 'SET_NOTIFICATION_PREFS';
export const SLOT_AVAILABLE = 'SLOT_AVAILABLE';
export const UN_VIEW_BACKGROUND = 'UN_VIEW_BACKGROUND';
export const VIEW_BACKGROUND = 'VIEW_BACKGROUND';
export const SEARCH_OTHER = 'SEARCH_OTHER';