import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './redux/actions';

class LoadingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hauliers_loaded: false,
            slots_loaded: false
        }
    }

    componentDidMount() {
        this.getHauliers();
        this.getSlots();
    }

    getHauliers = () => {
        this.props.get_hauliers(this.props.token, () => this.setState({hauliers_loaded:true}));
    };

    getSlots = () => {
        this.props.get_slots(this.props.token, () => this.setState({slots_loaded:true}));
    };

    render() {
        if (this.state.hauliers_loaded !== true || this.state.slots_loaded !== true) {
            return (
                <div className="loading-screen">
    
                </div>
            );
        }
        return this.props.children;
    }
}

function mapStateToProps(state, props) {
	return {
        token: state.user_reducer.token
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScreen);