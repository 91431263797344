import React, { Component } from 'react';
import moment from 'moment';
import ReactTimeout from 'react-timeout';

class Metric extends Component {
    render() {
        return (
            <div className="metric">
                <div className="value">
                    {this.props.value}
                </div>
                <div className="label">
                    {this.props.label}
                </div>
            </div>
        );
    }
    
}

class Today extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metrics:[]
        }
    }

    componentDidMount() {
        this.resolve_metrics();
    }

    componentDidUpdate(prevprops) {
        if (JSON.stringify(prevprops.slots) !== JSON.stringify(this.props.slots)) {
            this.resolve_metrics();
        }
    }


    resolve_metrics = () => {
        let slots = this.props.slots;
        let today_slots = slots.filter(x => x.deleted !== true).filter(x => moment(x.original_date_time).isSame(moment(), 'day'));
        let metrics = [];

        metrics.push({
            value:slots.length,
            label: "Bookings"
        })

        metrics.push({
            value: today_slots.length,
            label:"Bookings Today"
        });

        metrics.push({
            value: today_slots.filter(x => x.depart_time !== null).length,
            label: "Completed"
        });

        let arrived = today_slots.filter(x => x.arrive_time !== null);
        let not_arrived = today_slots.filter(x => x.arrive_time === null);

        metrics.push({
            value: arrived.filter(x => moment(x.original_date_time).isBefore(moment().arrive_time, 'hour')).length,
            label: "Arrived Late"
        });

        metrics.push({
            value: not_arrived.filter(x => moment(x.original_date_time).isBefore(moment(), 'hour')).length,
            label: "Late Outstanding"
        });

        this.setState({metrics});
    }

    render() {
        return (
            <div className="today">
                {this.state.metrics.map((m,i) => <Metric value={m.value} label={m.label} key={i} />)}
            </div>
        );
    }
}

export default ReactTimeout(Today);