import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import Close from '@material-ui/icons/Close';
import Slot from '../functional/slot/slot';

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
                <div className="box search-box-form">
                    <div style={{flexDirection:'row', alignItems:'center'}}>
                        <Close onClick={() => this.props.cancel()} style={{cursor:'pointer', fontSize:29, position:'relative', top:7, marginRight:10, color:'#000'}} />
                        <span className="heading">Search Results</span>
                        <div className="results">
                            {this.props.results.length > 0 ? this.props.results.map((r,i) => <Slot view={() => this.props.view(r)} search slot={r} key={i} />)
                            : <span className="no-results">No Results</span>}
                        </div>
                    </div>
                </div>

        );    
    }
}

function mapStateToProps(state) {
	return {
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);