import React, { Component } from 'react';
import Refresh from '@material-ui/icons/Refresh';
import Vis from '@material-ui/icons/Visibility';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';

import ReactTimeout from 'react-timeout';
import Cookies from 'universal-cookie';

class PauseButton extends Component {

    componentDidMount() {
        const cookies = new Cookies();
        let wallpaper = cookies.get('wallpaper') || null;
        if (wallpaper !== null) {
            this.props.toggle_wallpaper_mode(wallpaper);
        }
        
        this.props.setTimeout(this.props.pause_background, this.getRandomInt(2,4)*1000)
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    update_wallpaper = () => {
        fetch("https://api.logistics.albanyproducts.co.uk/wallpaper", {method:'POST', headers:{'Accept': 'application/json', 'Content-Type': 'application/json'}}).then(resp => resp.json()).then(data => this.props.set_wallpaper(data.wallpaper));
    }

    render() {
        return (
            <div className="pause-button">
                <div>
                    <Refresh onClick={() => this.update_wallpaper()} />
                </div>
                <div>
                    <Vis onMouseDown={() => this.props.view_background()} onMouseUp={() => this.props.un_view_background()} />
                </div>
            </div>
        );
    }

}

function mapStateToProps(state, props) {
	return {
        paused: state.user_reducer.background_paused,
        wallpaper_mode: state.user_reducer.wallpaper_mode,
        token: state.user_reducer.token
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default ReactTimeout(connect(mapStateToProps, mapDispatchToProps)(PauseButton));