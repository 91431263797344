import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';

class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_term: ""
        }
    }

    on_enter = (event) => {
        if (event.keyCode === 13) {
            this.search();
        }
    }

    search = () => {
        if (this.state.search_term !== null) {
            if (this.state.search_term.length > 2) {
                this.props.search(this.state.search_term, () => this.props.view());
            }
        }
    }

    handle_term = t => {
        t = t.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join('');
        this.setState({search_term: t});
    }
    
    componentDidMount() {
        document.getElementById("search_input").addEventListener("keydown", this.on_enter, false); 
    }

    componentWillUnmount() {
        document.getElementById("search_input").removeEventListener("keydown", this.on_enter, false); 
    }

    render() {
        return(
            <div className={`box search-box ${this.props.vanish === true ? 'vanish': ''}`}>
                <span className="Search-label">Search TIDS</span>
                <div className="search-inner">
                    <input id="search_input" className="search-input" value={this.state.search_term}
                        onChange={e => this.handle_term(e.target.value)}
                    />

                    <IconButton onClick={() => this.search()}>
                        <Search />    
                    </IconButton>

                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
	return {
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);