import React, { Component } from 'react';

export default class ClickImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            base_64: null,
        }
    }

    componentDidMount() {
        let url = this.props.img.url;
        fetch(url).then(response => response.text()).then(data => this.setState({base_64: 'data:image;base64,' + data}));
    }


    render() {
        if (this.state.base_64 === null) {
            return null;
        }

        return (
            <div onClick={() => this.props.show(this.state.base_64)} className="picture">
                <img alt="Load Report" src={this.state.base_64} style={{width:"100%", height:"100%", objectFit:'cover'}} />
            </div>
        );
    }
}