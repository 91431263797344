import React, { Component } from 'react';

export default class Pre extends Component {
    render() {
        let className = 'slot pre'
        if (this.props.selected === true) {
            className = "slot selected";
        }
        if (this.props.slot.deleted === true) {
            className = "slot deleted";
        }

        if (this.props.search === true) {
            className = className + " search-slot";
        }


        return (
            <div onClick={() => this.props.view()} className={className}>
                <div className="slot-top">
                </div>
                <div className="slot-body">
                    <span style={{color:'#fff'}}>Reserved by {this.props.slot.created_by_name}</span>
                </div>
            </div>
        );
    }
}