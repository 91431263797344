import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Close from '@material-ui/icons/Close';
import ErrorModal from '../modals/error_modal';
import { api_url } from '../../config';

const hours = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'];


class FormArea extends Component {
    render() {
        return (
            <div style={{alignSelf:'stretch', padding:5, marginBottom:5}}>
                {this.props.children}
            </div>
        );
    }
}


class NewBookingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment().add(1, 'days').format('YYYY-MM-DD'),
            time: '10:00',
            haulier:"",
            comments: "",
            draft_tid: "",
            tids:[],
            pre:false,
            direction:null,
            error:null,
            pallets:'0',
        };
    }

    pad = h => {
        h = h.toString();
        while (h.length < 2) {
            h = '0' + h;
        }
        return h;
    }

    handleDate = (date) => {
        this.setState({date:date});
    }

    handleTime = (time) => {
        if (moment(this.state.date).hour(time.split(':')[0]).isBefore(moment(),'hour')) {
            this.setState({error:'Must be in future'});
            return;
        }
        let hr = parseInt(time.split(':')[0]);
        if ((hr < 6 || hr > 16) && this.props.account_type === 'HAULIER') {
            this.setState({error: 'Please contact Albany to request a slot outside of normal hours (06:00 - 14:00)'})
            return;
        }
        this.setState({time:time});
    }

    handle_tid = (text) => {
        let tid = text.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1);
        this.setState({draft_tid:tid.join('')});
    }

    add_tid = async (cb=null) => {
        let tid = this.state.draft_tid;
        if (tid.toString().length !== 5) {
            this.setState({error: 'TIDs must be 5 digits'});
            return;
        }

        let s = await fetch(api_url, {
            method:'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify({action:'check_tid', tid: tid, token:this.props.token})
        }).then(response => response.json()).then(data => data.success);

        if (s === false) {
            this.setState({error: 'TID is not Unique'});
            return;
        }

        let tids = this.state.tids;
        tids.push(tid)
        this.setState({tids:tids, draft_tid:""}, () => { 
            try {
                cb();
            } catch(e) {
                
            }
        });
    }

    remove_tid = (tid) => {
        let tids = this.state.tids;
        tids = tids.filter(x => x !== tid);
        this.setState({tids:tids});
    }

    handle_pallets = p => {
        p = p.split('');
        p = p.filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1);
        p = p.join('');
        this.setState({pallets: p});
    }


    validateAndSubmit = async () => {
        if (moment(this.state.date).isBefore(moment(),'days')) {
            this.setState({error:'Date Must be in Future'});
            return;
        } else if (moment(this.state.date).isSame(moment(), 'days')) {
            if (moment().hour(this.state.time.split(':')[0]).isBefore(moment(), 'hour')) {
                this.setState({error:'Time Must be in Future'})
                return;
            }
        }


        if (this.state.pre !== true) {
            if ((this.state.haulier === "" || this.state.haulier === null) && this.state.pre === false) {
                this.setState({error: 'Must set a Haulier'});
                return;
            }
    
            if ((this.state.tids.length === 0) && this.state.pre === false) {
                await this.add_tid();
                if (this.state.tids.length === 0) {
                    this.setState({error: 'Please supply at least one TID'});
                    return;
                }
            }
    
    
            if (this.state.direction === null && this.state.pre === false) {
                this.setState({error: 'Please specify direction'});
                return;
            }
    
            if (this.state.pallets === null || this.state.pallets === "") {
                this.setState({error: 'No Pallet Count'});
                return;
            }
            if (parseInt(this.state.pallets) < 1) {
                this.setState({error: 'Zero Pallets?'});
                return;
            }
            if (parseInt(this.state.pallets) > 60) {
                this.setState({error: 'Too many Pallets'});
                return;
            }

            let slot_available = await fetch(api_url, {method:'POST', headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body:JSON.stringify({token: this.props.token, pallets: this.state.pallets, action: 'hour_is_available', date: this.state.date, hour: parseInt(this.state.time.split(':')[0])})
            }).then(resp => resp.json()).then(resp => resp.available);
            if (slot_available === false) {
                this.setState({error: 'Slot Unavailable'});
                return;
            }
        }


        let slot = {
            direction: this.state.direction,
            haulier_id: this.state.haulier,
            comments: this.state.comments,
            date: this.state.date,
            time:this.state.time,
            tids:this.state.tids,
            pre: this.state.pre,
            pallets: parseInt(this.state.pallets)
        }

        this.props.create_slot(this.props.token, slot, () => this.setState({
            date: moment().format('YYYY-MM-DD'),
            time: moment().add(2, 'hours').format('HH:MM'),
            haulier:"",
            comments: "",
            draft_tid: "",
            tids:[],
            pre: false,
            direction:'Inbound',
            pallets:'0'
        }, () => this.props.cancel()));
    }

    render() {
        return (
                <div className="new-booking-form">
                    <ErrorModal visible={this.state.error !== null} error={this.state.error} close={() => this.setState({error:null})} />
                    <div style={{flexDirection:'row', alignItems:'center'}}>
                        <Close onClick={this.props.cancel} style={{cursor:'pointer', fontSize:29, position:'relative', top:7, marginRight:10, color:'#000'}} />
                        <span className="heading">Create a Booking</span>
                    </div>
                    <div style={{alignSelf:'stretch', padding:20, marginBottom:10}}>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <div style={{flex:1}}>
                                <TextField
                                    id="date"
                                    label="Date"
                                    type="date"
                                    defaultValue={this.state.date}
                                    InputLabelProps={{shrink:true}}
                                    onChange={e => this.handleDate(e.target.value)}
                                    />
                            </div>

                            <div style={{flex:1}}>
                                <InputLabel htmlFor="time-select">Time</InputLabel>
                                <Select
                                    value={this.state.time}
                                    onChange={(v) => this.handleTime(v.target.value)}
                                    inputProps={{
                                        id: 'time-select'
                                    }}
                                    style={{width:250}}>
                                        {hours.map((h,i) => <MenuItem value={h} key={i}>{h}</MenuItem>)}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <FormArea>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={this.state.pre}
                                onChange={() => this.setState({pre:!this.state.pre})}
                                value="pre-checked"
                                color="primary"
                            />
                            }
                            label="Pre-Booking?"
                            style={{color:'#000'}}
                            />
                    </FormArea>
                    
                    {this.state.pre === false ? 
                        <div>
                            <FormArea>
                                <InputLabel htmlFor="direction-select">Direction</InputLabel>
                                <Select
                                    value={this.state.direction}
                                    onChange={(v) => this.setState({direction:v.target.value})}
                                    inputProps={{
                                        id: 'direction-select'
                                    }}
                                    style={{width:250}}>
                                        <MenuItem value="inbound">Inbound</MenuItem>
                                        <MenuItem value="outbound">Outbound</MenuItem>
                                </Select>
                            </FormArea>

                            <FormArea>
                                <InputLabel htmlFor="haulier">Haulier</InputLabel>
                                <Select
                                    value={this.state.haulier}
                                    onChange={(v) => this.setState({haulier: v.target.value})}
                                    inputProps={{
                                        name: 'age',
                                        id: 'haulier',
                                    }}
                                    style={{width:250}}
                                    >
                                        {this.props.hauliers.map((h,i) => <MenuItem value={h.id} key={i}>{h.name}</MenuItem>)}
                                </Select>
                            </FormArea>

                            <FormArea>
                                <InputLabel htmlFor="tid-entry">Job Numbers</InputLabel>
                                <div style={{alignSelf:'stretch', flexDirection:'row'}}>
                                    <TextField
                                        id="tid-entry"
                                        value={this.state.draft_tid}
                                        onChange={(v) => this.handle_tid(v.target.value)}
                                        />
                                    <Button onClick={this.add_tid}>Add</Button>
                                </div>
                            </FormArea>

                            <FormArea>
                                <div style={{flexDirection:'row', alignSelf:'stretch', display:'flex'}}>
                                    {this.state.tids.map((t,i) => <div onClick={() => this.remove_tid(t)} style={{cursor:'pointer', padding:3, margin:5, borderRadius:2, backgroundColor:'red', color:'white', display:'flex', justifyContent:'center', alignItems:'center', maxWidth:90}} key={i}>{t}</div>)}
                                </div>
                            </FormArea>
                            
                            <FormArea>
                                <InputLabel htmlFor="pallets">Number of Pallets</InputLabel>
                                <TextField
                                    id="pallets"
                                    value={this.state.pallets}
                                    onChange={(v) => this.handle_pallets(v.target.value)}
                                    />
                            </FormArea>
                        </div>
                    : null}


                    <FormArea>
                        <TextField
                            id="commentsbox"
                            label="Comments"
                            multiline
                            rowsMax="4"
                            value={this.state.comments}
                            onChange={(e) => this.setState({comments: e.target.value})}
                            margin="normal"
                            variant="outlined"
                            style={{width:250}}
                            />
                    </FormArea>

                    <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'space-between', padding:20}}>
                        <Button onClick={() => this.props.cancel()}>Cancel</Button>
                        <Button color="primary" onClick={() => this.validateAndSubmit()}>Create</Button>
                    </div>
                </div>
        );    
    }
}

function mapStateToProps(state, props) {
	return {
        hauliers: state.hauliers_reducer.hauliers,
        token: state.user_reducer.token,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBookingForm);