import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';

class NewHaulierForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            haulier_name:null,
            users: [],
            draft_name: "",
            draft_email: "",
        };
    }

    add_user = (name, email) => {
        let users = this.state.users;
        users.push({name: name, email:email});
        this.setState({users:users, draft_email: "", draft_name: ""});
    }

    render() {
        return (

                <div className="new-booking-form">
                    <div style={{flexDirection:'row', alignItems:'center'}}>
                        <Close onClick={this.props.cancel} style={{cursor:'pointer', fontSize:29, position:'relative', top:7, marginRight:10, color:'#000'}} />
                        <span className="heading">Add a Haulier</span>
                    </div>

                    <div style={{alignSelf:'stretch', padding:20, marginBottom:10}}>
                        <TextField
                            id="haulier_name"
                            label="Haulier Name"
                            value={this.state.haulier_name}
                            onChange={e => this.setState({haulier_name: e.target.value})}
                        />
                    </div>

                    <span className="subheading">Haulier Users</span>

                    {this.state.users.length > 0 ?
                        <div style={{alignSelf:'stretch', padding:20, marginBottom:10}}>
                                <table className="haulier-contacts" style={{alignSelf:'stretch'}}>
                                    <thead>
                                        <tr>
                                            <th className="">
                                                Name
                                            </th>
                                            <th style={{display:'inline-block !important'}}>
                                                Email
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.users.map((u,i) => 
                                            <tr>
                                                <td style={{display:'inline-block !important'}}>{u.name}</td>
                                                <td style={{display:'inline-block !important'}}>{u.email}</td>
                                            </tr>)}
                                    </tbody>
                                </table>
                        </div> : null}

                    <div style={{alignSelf:'stretch', padding:20, marginBottom:10}}>
                        <div style={{flexDirection:'row', display:'flex'}}>
                            <TextField
                                id="user-name"
                                label="Name"
                                value={this.state.draft_name}
                                onChange={e => this.setState({draft_name: e.target.value})}
                                style={{marginRight:10}}
                            />

                            <TextField
                                id="user-email"
                                label="Email"
                                value={this.state.draft_email}
                                onChange={e => this.setState({draft_email: e.target.value})}
                            />

                            <Button color="secondary" onClick={() => this.add_user(this.state.draft_name, this.state.draft_email)}>Add</Button>
                        </div>
                    </div>

                    <div style={{alignSelf:'stretch', padding:20, marginBottom:10, flexDirection:'row', justifyContent:'space-between', display:'flex'}}>
                        <Button onClick={() => this.props.cancel()}>cancel</Button>
                        <Button color="primary" variant="contained" onClick={() => this.props.create_haulier(this.props.token, {name: this.state.haulier_name, users: this.state.users}, () => this.setState({haulier_name: null}, () => this.props.cancel()))}>Create</Button>
                    </div>
                </div>

        );    
    }
}

function mapStateToProps(state, props) {
	return {
        hauliers: state.hauliers_reducer.hauliers,
        token: state.user_reducer.token,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NewHaulierForm);