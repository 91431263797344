import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Refresh from '@material-ui/icons/Refresh';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import YesNoModal from '../modals/yes_no_modal';
import ErrorModal from '../modals/error_modal';
import ClickImage from '../visual/click_image';
import ImageModal from '../modals/image_modal';
import Slot from '../functional/slot/slot';
import IssueModal from '../modals/issues_modal';
import { api_url } from '../../config';
import StatusTimes from '../modals/multi_time_picker';
import { timezone } from '../../config';


const hours = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'];
const saturday_hours = ['06:00','07:00','08:00','09:00'];


const moment_or_null = t => {
    t = moment(t).tz(timezone);
    if (t._isValid === true) {
        return t;
    } 
    return null;
 
}

const pad = t => {
    t = t.toString();
    while (t.length < 2) {
        t = '0' + t;
    }
    return t;
}

class StatusLevel extends Component {
    render() {
        return (
            <div className="status">
                <div className="status-value" onClick={this.props.time === null ? () => this.props.update() : () => this.props.adjust()}>
                    {this.props.time === null ? 
                        <CloseIcon style={{fontSize:30, color:'#000'}} />
                    :
                        <div style={{alignSelf:'stretch', flexDirection:'column', alignItems:'center'}}>
                            <Check style={{fontSize:30, color:'green'}} />
                            <span style={{color:'#000'}}>{moment(this.props.time).tz(timezone).format('HH:mm')}</span>
                        </div>
                    }
                </div>
                <div className="status-label">
                    {this.props.label}
                </div>
            </div>

        );
    }
}

const blank_state = {
    date: moment().format('YYYY-MM-DD'),
    weekday:moment().day(),
    time: moment().add(2, 'hours').format('HH:MM'),
    haulier:null,
    comments: [],
    draft_comment: "",
    draft_tid: "",
    tids:[],
    direction:'Inbound',
    id:null,
    pre: false,
    deleting: false,
    error:null,
    pictures:[],
    pallets:'0',
    date_changed: false,
    time_changed: false,
    issues_modal_detail: false,
    issues_modal: false
}

class EditBookingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment().format('YYYY-MM-DD'),
            weekday:moment().day(),
            time: moment().add(2, 'hours').format('HH:MM'),
            haulier:null,
            comments: [],
            draft_comment: "",
            draft_tid: "",
            tids:[],
            direction:'Inbound',
            id:null,
            pre: false,
            deleting: false,
            error:null,
            pictures:[],
            image: null,
            image_width: 1,
            image_height: 1,
            pallets: '0',
            date_changed: false,
            time_changed: false,
            issues_modal: false,
            issues_modal_detail: false,
            arrive_time: null,
            load_tip_start_time: null,
            load_tip_end_time: null,
            depart_time: null
        };
    }

    handle_status_time = (typ, time) => {
        if (typ === 'arrive') {
            this.setState({arrive_time: time})
        } else if (typ === 'start') {
            this.setState({load_tip_start_time: time})
        } else if (typ === 'end') {
            this.setState({load_tip_end_time: time})
        } else if (typ === 'depart') {
            this.setState({depart_time: time})
        }
    }

    save_status_times = () => {
        const times = {
            arrive: this.state.arrive_time,
            start: this.state.load_tip_start_time,
            end: this.state.load_tip_end_time,
            depart: this.state.depart_time
        }

        this.props.change_status_time(this.props.token, this.state.id, times, () => this.setState({changing_times: false}));        
    }
   

    showimage = (base, width, height) => {
        let ratio;

        if (width > height) {
            if (width > 750) {
                ratio = width / height;
                width = 750;
                height = 750 / ratio;
            }
        } else {
            ratio = height / width;
            height = 750;
            width = 750 / ratio;
        }

        this.setState({image: base, image_width: width, image_height: height});
    }

    assignSlotToState = (slot) => {
        if (slot === null) {
            this.setState(blank_state);
            return;
        }
        let tids = slot.payloads.map(x => x.transport_id);
        let direction = slot.direction;
        let or = moment(slot.original_date_time).tz('Europe/London');
        let dt = or.format("YYYY-MM-DD");
        let tm = pad(parseInt(slot.original_date_time.split('T')[1].split(':')[0])) + ':00';
        let hid = null;
        if(slot.haulier) {
            hid = slot.haulier.id;
        }

        this.setState({
            date:dt,
            id: slot.id,
            time:tm,
            haulier: hid,
            comments:slot.comments,
            direction: direction,
            tids: tids,
            arrive_time: slot.arrive_time,
            load_tip_end_time: slot.load_tip_end_time,
            load_tip_start_time: slot.load_tip_start_time,
            depart_time: slot.depart_time,
            pre: slot.pre,
            pictures: slot.pictures,
            pallets: slot.pallets,
            issues_modal: false,
            issues_modal_detail: false,
        })
    }

    componentDidMount() {
        this.assignSlotToState(this.props.slot);
    }

    UNSAFE_componentWillReceiveProps(newprops) {
        this.assignSlotToState(newprops.slot);
    }

    pad = h => {
        h = h.toString();
        while (h.length < 2) {
            h = '0' + h;
        }
        return h;
    }

    handleDate = (date) => {
        let dt = moment(date);
        let wd = dt.day();
        if (dt !== this.state.date && this.props.account_type === 'HAULIER') {
            this.setState({error: 'Please contact Albany to change the booking Date'});
            return;
        }

        if (dt.isBefore(moment(),'day')) { 
            this.setState({error: 'Date must be in future'});
            return;

        } if (dt.isSame(moment(), 'day')){
            if (moment().hour(this.state.time.split(':')[0]).isBefore(moment())) {
                this.setState({error: 'Daate must be in future'});
                return;
            }
        }
        this.setState({date:date, weekday:wd, date_changed: true});
    }

    handleTime = (time) => {
        let current = JSON.parse(JSON.stringify(this.state.time));
        if (moment(this.state.date).hour(time.split(':')[0]).isBefore(moment(),'hour')) {
            this.setState({error:'Must be in future'});
            return;
        }
        let hr = parseInt(time.split(':')[0]);
        if ((hr < 6 || hr > 16) && this.props.account_type === 'HAULIER') {
            this.setState({error: 'Please contact Albany to request a slot outside of normal hours (06:00 - 14:00)'})
            return;
        }
        this.setState({time:time, time_changed: current !== time});
    }

    handle_tid = (text) => {
        let tid = text.split('').filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1);
        this.setState({draft_tid:tid.join('')});
    }

    handle_pallets = p => {
        if (moment(this.state.date).isSame(moment(), 'day') && this.props.account_type === 'HAULIER') {
            this.setState({error: 'Please contact Albany to change deliverable qty'});
            return;
        }
        p = p.split('');
        p = p.filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1);
        p = p.join('');
        this.setState({pallets: p});
    }

    add_tid = () => {
        if (this.state.tids.indexOf(this.state.draft_tid) === -1) {

        }
        let tid = this.state.draft_tid;
        let tids = this.state.tids;
        tids.push(tid)
        this.setState({tids:tids, draft_tid:""});
    }

    handleComment = comment => {
        this.setState({draft_comment: comment});
    }

    submit_comment = comment => {
        let comments = this.state.comments;
        comments.push({user:this.props.name, comment:this.state.draft_comment, time:moment().format()})
        this.setState({comments:comments, draft_comment:""});
    }


    remove_tid = (tid) => {
        let tids = this.state.tids;
        if (tids.length === 1) {
            this.setState({error: 'Slot must be assigned 1 TID minimum'});
            return;
        }
        tids = tids.filter(x => x !== tid);
        this.setState({tids:tids});
    }

    validateAndSubmit = async (close=true) => {
        let slot = {
            direction: this.state.direction,
            haulier_id: this.state.haulier,
            comments: this.state.comments,
            date: this.state.date,
            time:this.state.time,
            tids:this.state.tids,
            id: this.state.id,
            pallets: this.state.pallets,
        }

        if (slot.pre !== true) {
            if (slot.direction !== 'inbound' && slot.direction !== 'outbound') {
                this.setState({error: "Must set a direction"});
                return;
            }
            if (slot.pallets < 1 || slot.pallets === null || slot.pallets === "") {
                this.setState({error: "Check Pallets"});
                return;
            }
            if (moment(this.state.date).isBefore(moment(), 'day') && this.state.date_changed === true) {
                this.setState({error:'Date must be in future'});
                return;
            }
            if (slot.haulier_id === null || slot.haulier_id === "") {
                this.setState({error: 'Must set Haulier'});
                return;
            }

            if (this.state.date_changed === true || this.state.time_changed === true) {
                let slot_available = await fetch(api_url, {method:'POST', headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
                body:JSON.stringify({token: this.props.token, action: 'hour_is_available', pallets: slot.pallets, date: this.state.date, hour: parseInt(this.state.time.split(':')[0])})
                }).then(resp => resp.json()).then(resp => resp.available);
                if (slot_available === false) {
                    this.setState({error: 'Slot Unavailable'});
                    return;
                }
            }
        }


        this.props.edit_slot(this.props.token, slot, () => {
            if (close === true) {
                this.props.cancel();
            }
        });
    }

    delete = () => {
        if (this.props.slot.deleted === true) {
            this.props.delete_slot(this.props.token, this.state.id, false, () => {});
        } else {
            this.setState({deleting: true});
        }
    }

    load_tip_end = (issues, type=null, comment=null) => {
        if (issues === true) {
            this.props.update_slot_status(this.props.token, this.state.id, 'load_tip_end', issues, type + ' > ' + comment)
        } else {
            this.props.update_slot_status(this.props.token, this.state.id, 'load_tip_end', issues);
        }
        this.setState({issues_modal: false, issues_modal_detail: false});
    }

    handle_direction = direction => {
        if (this.props.account_type === 'HAULIER') {
            this.setState({error: 'Hauliers cannot change booking direction'});
            return;
        }
        this.setState({direction: direction});
    }

    reset_status = () => {
        this.props.update_slot_status(this.props.token, this.state.id, 'reset', false);
    }

    render() {
        return (
                <div className="new-booking-form">
                    <Refresh onClick={() => this.reset_status()} style={{fontSize:30, color:'rgb(100,100,100)', position:'absolute', top:20, right:20, cursor:'pointer'}} />
                    {this.props.slot !== null ? <Slot className="top-slot" slot={this.props.slot} /> : null}
                    <StatusTimes
                        save={this.save_status_times}
                        update={this.handle_status_time}
                        arrive={moment_or_null(this.state.arrive_time)}
                        start={moment_or_null(this.state.load_tip_start_time)}
                        end={moment_or_null(this.state.load_tip_end_time)}
                        depart={moment_or_null(this.state.depart_time)}
                        visible={this.state.changing_times}
                        close={() => this.setState({changing_times: false})} />

                    <ImageModal image={this.state.image} width={this.state.image_width} height={this.state.image_height} close={() => this.setState({image:null})} visible={this.state.image !== null} />
                    <ErrorModal visible={this.state.error !== null} close={() => this.setState({error:null})} error={this.state.error} />
                    <YesNoModal title="Any Issues?" visible={this.state.issues_modal} yes={() => this.setState({issues_modal_detail: true, issues_modal: false})} no={() => this.load_tip_end(false)} />
                    
                    <IssueModal visible={this.state.issues_modal_detail} save={(type, comm) => this.load_tip_end(true, type, comm)} close={() => this.setState({issues_modal_detail: false, issues_modal: true})} />
                    
                    <YesNoModal title="Delete Booking?" visible={this.state.deleting} yes={() => this.props.delete_slot(this.props.token, this.state.id, false, () => this.setState({deleting:false}, this.props.cancel))} no={() => this.setState({deleting:false})} />
                    <div style={{flexDirection:'row', alignItems:'center'}}>
                            <Close onClick={this.props.cancel} style={{cursor:'pointer', fontSize:29, position:'relative', top:7, marginRight:10, color:'#000'}} />
                            <span className="heading">View a Booking</span>
                        </div>
                        <div style={{flexDirection:'row', display:'flex', alignSelf:'stretch'}}>
                            <div style={{flex:1}}>
                                <div style={{alignSelf:'stretch', padding:10}}>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                        <div style={{flex:1}}>
                                            <TextField
                                                id="date"
                                                label="Date"
                                                type="date"
                                                value={this.state.date}
                                                InputLabelProps={{shrink:true}}
                                                onChange={e => this.handleDate(e.target.value)}
                                                />
                                        </div>

                                        <div style={{flex:1}}>
                                            <InputLabel htmlFor="time-select">Time</InputLabel>
                                            <Select
                                                value={this.state.time}
                                                onChange={(v) => this.handleTime(v.target.value)}
                                                inputProps={{
                                                    id: 'time-select'
                                                }}
                                                style={{width:250}}>
                                                    {this.state.weekday !== 6 ? hours.map((h,i) => <MenuItem value={h} key={i}>{h}</MenuItem>) : saturday_hours.map((h,i) => <MenuItem value={h} key={i}>{h}</MenuItem>)}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                
                                {this.state.pre !== true ? 
                                    <div>
                                        <div className="form-area">
                                            <InputLabel htmlFor="direction-select">Direction</InputLabel>
                                            <Select
                                                value={this.state.direction}
                                                onChange={(v) => this.handle_direction(v.target.value)}
                                                inputProps={{
                                                    id: 'direction-select'
                                                }}
                                                style={{width:250}}>
                                                    <MenuItem value="inbound">Inbound</MenuItem>
                                                    <MenuItem value="outbound">Outbound</MenuItem>
                                            </Select>
                                        </div>

                                        <div className="form-area">
                                            <InputLabel htmlFor="haulier">Haulier</InputLabel>
                                            <Select
                                                value={this.state.haulier}
                                                onChange={(v) => this.setState({haulier: v.target.value})}
                                                inputProps={{
                                                    name: 'age',
                                                    id: 'haulier',
                                                }}
                                                style={{width:250}}
                                                >
                                                    {this.props.hauliers.map((h,i) => <MenuItem value={h.id} key={i}>{h.name}</MenuItem>)}
                                            </Select>
                                        </div>

                                        <div className="form-area">
                                            <InputLabel htmlFor="pallets">Number of Pallets</InputLabel>
                                            <TextField
                                                id="pallets"
                                                value={this.state.pallets}
                                                onChange={(v) => this.handle_pallets(v.target.value)}
                                                />
                                        </div>

                                        <div className="form-area">
                                            <InputLabel htmlFor="tid-entry">Job Numbers</InputLabel>
                                            <div style={{alignSelf:'stretch', flexDirection:'row'}}>
                                                <TextField
                                                    id="tid-entry"
                                                    value={this.state.draft_tid}
                                                    onChange={(v) => this.handle_tid(v.target.value)}
                                                    />
                                                <Button onClick={this.add_tid}>Add</Button>
                                            </div>
                                        </div>

                                        

                                        <div className="form-area">
                                            <div style={{flexDirection:'row', alignSelf:'stretch', display:'flex'}}>
                                                {this.state.tids.map((t,i) => <div key={i} onClick={() => this.remove_tid(t)} style={{cursor:'pointer', padding:3, margin:5, borderRadius:2, backgroundColor:'red', color:'white', display:'flex', justifyContent:'center', alignItems:'center', maxWidth:90}}>{t}</div>)}
                                            </div>
                                        </div>
                                    </div>
                                :null}
                            </div>

                            {this.state.pre !== true ?
                                <div style={{width:100, minWidth:100, flexDirection:'column', display:'flex', justifyContent:'flex-start'}}>
                                    <StatusLevel adjust={this.props.account_type === 'HAULIER' ? () => {} : () => this.setState({changing_times: true})} update={this.props.account_type === 'HAULIER' ? () => {} : () => this.props.update_slot_status(this.props.token, this.state.id, 'arrived')} time={this.state.arrive_time} label="Arrived" />
                                    <StatusLevel adjust={this.props.account_type === 'HAULIER' ? () => {} : () => this.setState({changing_times: true})} update={this.props.account_type === 'HAULIER' ? () => {} : () => this.props.update_slot_status(this.props.token, this.state.id, 'load_tip_start')} time={this.state.load_tip_start_time} label={this.state.direction === 'inbound' ? 'Tip Start' : 'Load Start'} />
                                    <StatusLevel adjust={this.props.account_type === 'HAULIER' ? () => {} : () => this.setState({changing_times: true})} update={this.props.account_type === 'HAULIER' ? () => {} : this.props.slot.load_tip_end_time === null ? () => this.setState({issues_modal: true}) : () => this.load_tip_end(false)} time={this.state.load_tip_end_time} label={this.state.direction === 'inbound' ? 'Tip Finish' : 'Load Finish'} />
                                    <StatusLevel adjust={this.props.account_type === 'HAULIER' ? () => {} : () => this.setState({changing_times: true})} update={this.props.account_type === 'HAULIER' ? () => {} : () => this.props.update_slot_status(this.props.token, this.state.id, 'departed')} time={this.state.depart_time} label="Departed" />
                                </div>
                            : null}
                        </div>

                        <div className="form-area">
                            <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', overflowX: 'auto'}}>
                                {this.state.pictures.map((p,i) => <ClickImage show={(base) => this.showimage(base, p.width, p.height)} key={i} img={p} />)}
                            </div>
                        </div>

                        <div className="form-area">
                            <div style={{flex:1, maxHeight:120, overflowY:'auto'}}>
                                <table className="comments-table">
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th style={{width: "250px !important"}}>Comment</th>
                                            <th>Time</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {this.state.comments.reverse().map((c,i) => <tr key={i}>
                                            <td>{c.user}</td>
                                            <td>{c.comment}</td>
                                            <td>{moment(c.time).format("HH:mm DD/MM/YY")}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="form-area" style={{alignItems:'center', flexDirection:'row', display: 'flex'}}>
                            <div style={{flex:1, maxWidth:250}}>
                                <InputLabel htmlFor="commentsbox">Comments</InputLabel>
                                <TextField
                                    id="commentsbox"
                                    multiline
                                    rowsMax="4"
                                    value={this.state.draft_comment}
                                    onChange={(e) => this.handleComment(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width:250}}
                                    />
                            </div>
                            <Button variant="contained" color="secondary" onClick={() => this.submit_comment()} style={{positon:'relative', top:12, left:10}}>Submit</Button>
                        </div>

                        <div style={{display:'flex', flexDirection:'row', alignSelf:'stretch', justifyContent:'space-between', padding:20}}>
                            <Button onClick={() => this.props.cancel()}>Cancel</Button>
                            {this.props.account_type !== 'HAULIER' ? <Button onClick={() => this.delete()} color="red" variant="contained">{this.props.slot.deleted === false ? "Delete" : "Restore"}</Button> : null}
                            <Button variant="contained" color="primary" onClick={() => this.validateAndSubmit()}>Save</Button>
                        </div>
                </div>
        );    
    }
}

function mapStateToProps(state, props) {
	return {
        hauliers: state.hauliers_reducer.hauliers,
        slot: state.slots_reducer.slot_in_view,
        token: state.user_reducer.token,
        account_type: state.user_reducer.account_type,
        name: state.user_reducer.name
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBookingForm);