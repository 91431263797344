import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './redux/actions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Cookies from 'universal-cookie';
import CookiesModal from './components/modals/cookies';

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            resetting: false,
            cookies_warning: true
        }
    }

    email = null;
    password = null;

    on_enter = (event) => {
        if (event.keyCode === 13) {
                if (this.state.email === "") {
                    this.email.children[1].children[0].focus();
                    return;
                }
                if (this.state.password === "") {
                    this.password.children[1].children[0].focus();
                    return;
                }
                this.props.login(this.state.email, this.state.password, (token) => this.handleToken(token))
        }
    }

    UNSAFE_componentWillMount() {
        fetch("https://api.logistics.albanyproducts.co.uk/wallpaper", {method:'POST', headers:{'Accept': 'application/json', 'Content-Type': 'application/json'}}).then(resp => resp.json()).then(data => this.props.set_wallpaper(data.wallpaper));
    }

    cookies_ok = () => {
        const cookies = new Cookies();
        cookies.set('cookies_ok', 'ok');
        this.setState({cookies_warning: false});
    }

    disable_cookies = () => {
        const cookies = new Cookies();
        cookies.remove('cookies_ok');
        cookies.remove('token');
        cookies.remove('wallpaper');
        this.setState({cookies_warning: false});
    }

    async componentDidMount() {
        let url = new URL(window.location);
        let token = url.searchParams.get("auth");
        if (token) {
            document.getElementById('email_address').removeEventListener("keydown", this.on_enter, false);
            document.getElementById('password').removeEventListener("keydown", this.on_enter, false);
            this.props.token_login(token);
        } else {
            const cookies = new Cookies();
            let cookies_ok = await cookies.get('cookies_ok') || false;
            if (cookies_ok === 'ok') {
                this.setState({cookies_warning: false, show_cookies_modal: false});
            } else {
                this.setState({cookies_warning: true, show_cookies_modal: true});
            }

            token = cookies.get('token') || null;
            if (token !== null) {
                this.props.token_login(token);
            }
        }

        document.getElementById('email_address').addEventListener("keydown", this.on_enter, false);
        document.getElementById('password').addEventListener("keydown", this.on_enter, false);
    }

    login = () => {
        document.getElementById('email_address').removeEventListener("keydown", this.on_enter, false);
        document.getElementById('password').removeEventListener("keydown", this.on_enter, false);
        this.props.login(this.state.email, this.state.password, (token) => this.handleToken(token));
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.on_enter, false);
    }

    handleToken = token => {
        const cookies = new Cookies();
        let cookies_ok = cookies.get('cookies_ok') || false;
        if (cookies_ok === 'ok') {
            cookies.set('token', token, {
                path:'/',
                maxAge: 60*60*24*7,
            });
        }
    }

    render() {
        if (this.props.logged_in === false || this.state.cookies_warning === true) {
            return (
                <div className={`login-screen ${this.props.viewing_background === true ? 'hidden' : ''}`}>
                    {this.state.show_cookies_modal === true ? <CookiesModal
                        visible={this.state.cookies_warning === true}
                        close={() => {}}
                        ok={this.cookies_ok}
                        not_ok={this.disable_cookies}
                        /> : null}
                    <form>
                    <div className="login-box">
                        <div className="heading">{this.state.resetting === false ? 'Welcome' : 'Reset your password'}</div>
                        <TextField
                            autoFocus
                            ref={ref => this.email = ref}
                            id="email_address"
                            label="Email-Address"
                            value={this.state.email}
                            onChange={(e) => this.setState({email: e.target.value})}
                            margin="normal"
                            type="email-address"
                            name="email"
                            autoComplete="username"
                        />

                        <div style={{height:20}}></div>

                        {this.state.resetting === false ?
                            <TextField
                                ref={ref => this.password = ref}
                                id="password"
                                label="Password"
                                type="password"
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                                margin="normal"
                                name="password"
                                autoComplete="password"
                                />
                        : null}

                        <div style={{height:60, alignSelf:'stretch'}}>
                            {this.props.login_error === true ? <span className="login-error">Incorrect Login Details</span> : null}
                            {this.props.reset_message === true ? <span className="reset-message">Check Your Email</span> : null}
                        </div>

                        <div style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', display:'flex', paddingRight:20, paddingTop:10, alignItems:'center'}}>
                            <span onClick={() => this.setState({resetting: !this.state.resetting})} className="forgot-password">{this.state.resetting === false ? 'Forgot?' : 'Sign-In'}</span>
                            <Button className="menu-button" onClick={this.state.resetting === false ? () => this.login() : () => this.props.reset_password(this.state.email)} variant="contained">{this.state.resetting === false ? 'Login' : 'Reset'}</Button>
                        </div>
                    </div>
                    </form>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

function mapStateToProps(state, props) {
	return {
        logged_in: state.user_reducer.logged_in,
        login_error: state.user_reducer.login_error,
        reset_message: state.user_reducer.reset_message,
        viewing_background: state.user_reducer.viewing_background
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);